export default {
  HOME_PAGE_NAME_LABEL: '',
  WISHLIST_PAGE_NAME_LABEL: '',
  LOGOUT_LABEL: '',
  UPCOMING_LABEL: '',
  UPCOMING_LABEL_HP_RED: '',
  UPCOMING_LABEL_DESCR: '',
  SCHEDULED_LABEL: '',
  EXPIRING_LABEL: '',
  EXPIRING_PRICEABLE_LABEL: '',
  OVERDUE_LABEL: '',
  DONE_LABEL: '',
  TEAM_BADGE_DONE: 'DONE',
  TEAM_BADGE_TODO: 'TO DO',
  DUESOON_LABEL: '',
  DUE_DATE_LABEL: '',
  ATTENDED_LABEL: '',
  MISSED_LABEL: '',
  IN_PROGRESS_LABEL: '',
  TO_BEGIN_LABEL: '',
  CONTINUE_LABEL: '',
  NO_LABEL: '',
  CANCEL_BOOKING_LABEL: '',
  TIME_DURATION_LABEL: '',
  CREDITS_LABEL: '',
  LAUNCH_LABEL: '',
  JOIN_LABEL: '',
  NEW_LABEL: '',
  HOT_LABEL_WEB: '',
  MOST_LIKE_LABEL: '', //no more used in future
  PRODUCTS_LABEL: '', //no more used in future?
  SKILLS_LABEL: '', //no more used in future?
  COMPLIANCE_LABEL: '', //no more used in future?
  COMPANY_DNA_LABEL: '', //no more used in future?
  RESULTS_LABEL: '',
  MANDATORY_LABEL: '',
  OPTIONAL_LABEL: 'Optional',
  NEWEST_LABEL: '',
  RELEVANCE_LABEL: '',
  SHORTEST_LABEL: '',
  TITLE_AZ_LABEL: '',
  TITLE_ZA_LABEL: '',
  LOAD_MORE_LABEL: '',
  LANGUAGE_LABEL: '',
  ASSIGNMENT_LABEL: '',
  HOUSE_BRANDS_LABEL: '',
  LICENSED_BRANDS_LABEL: '',
  THIRD_PART_BRANDS_LABEL: '',
  COMPLETED_COURSES_LABEL: '',
  TRAINING_PASSPORT_LABEL: '',
  SEARCH_LABEL: '',
  COURSE_TYPE_LABEL: '',
  COMPLETED_ON_LABEL: '',
  DURATION_LABEL: '',
  LAST_UPDATE_LABEL: '',
  UPDATE_NOW_LABEL: '',
  XLSX_VERSION_LABEL: '',
  PDF_VERSION_LABEL: '',
  JANUARY_LABEL: '',
  FEBRUARY_LABEL: '',
  MARCH_LABEL: '',
  APRIL_LABEL: '',
  MAY_LABEL: '',
  JUNE_LABEL: '',
  JULY_LABEL: '',
  AUGUST_LABEL: '',
  SEPTEMBER_LABEL: '',
  OCTOBER_LABEL: '',
  NOVEMBER_LABEL: '',
  DECEMBER_LABEL: '',
  COMPLETED_LABEL: '',
  BRAND_FILTER_ALL: '',
  CTYPE_INTERACTIVE: '',
  CTYPE_FACE_TO_FACE: '',
  ERROR_MESSAGE: "An error occurred! Please try again later or contact website's administrator.",
  CTYPE_VIRTUAL_CLASSROOM: '',
  CTYPE_LEARNING_PATH: '',
  CTYPE_PDF: '',
  CTYPE_EXTERNAL_CONTENT: '',
  CTYPE_MULTIACTIVITY: '',
  CTYPE_PODCAST: '',
  CTYPE_LIVESTREAM: '',
  DATE_LABEL: '',
  CITY_LABEL: '',
  ADDRESS_LABEL: '',
  STORE_LABEL: '',
  VIRTUAL_LOCATION_LABEL: '',
  WAITING_ATTENDANCE_CHECK_LABEL: '',
  ABBR_JAN_LABEL: '',
  ABBR_FEB_LABEL: '',
  ABBR_MAR_LABEL: '',
  ABBR_APR_LABEL: '',
  ABBR_MAY_LABEL: '',
  ABBR_JUN_LABEL: '',
  ABBR_JUL_LABEL: '',
  ABBR_AUG_LABEL: '',
  ABBR_SEP_LABEL: '',
  ABBR_OCT_LABEL: '',
  ABBR_NOV_LABEL: '',
  ABBR_DEC_LABEL: '',
  DURATION_MIN_LABEL: '',
  DURATION_HOUR_LABEL: '',
  DURATION_DAY_LABEL: '',
  DURATION_WEEK_LABEL: '',
  CTYPE_VIDEO: '',
  VIEW_ALL_LABEL: 'view all',
  COURSES_LABEL: '',
  BACK_TO_UNIVERSITY: '',
  LENS_TECHNOLOGIES_LABEL: '',
  READ_MORE_LABEL: '',
  READ_LESS_LABEL: '',
  FILTER_ONLINE_COURSES_LABEL: '',
  FILTER_CLASSES_LABEL: '',
  FILTER_SUBTITLED_VIDEO: '',
  FILTER_STATUS: '',
  FILTER_STATUS_NEW: '',
  FILTER_STATUS_TO_BE_STARTED: '',
  FILTER_STATUS_TO_BE_CONTINUED: '',
  FILTER_STATUS_COMPLETED: '',
  FILTER_STATUS_TO_BE_COMPLETED: '',
  FILTER_STATUS_BOOKED: '',
  NO_NOTIFICATIONS_LABEL: '',
  AGO_LABEL: '',
  NOW_LABEL: '',
  MARK_ALL_AS_READ_LABEL: '',
  ADD_TO_FAVOURITES: '',
  REMOVE_TO_FAVOURITES: '',
  ADD_ALL_TO_FAVOURITES: '',
  ADDED_ALL_TO_FAVOURITES: '',
  BOOKED: '',
  NOT_AVAILABLE: '',
  NOT_YET_AVAILABLE: '',
  COMPLETE_COURSE_TO_UNLOCK: '',
  COMING_SOON_LABEL: '',
  ADD_FAVOURITES_TO_BE_NOTIFIED: '',
  FILTER_AND_SORTING: '',
  RESET_FILTERS: '',
  TOPICS: '',
  FILTER_BRAND: '',
  LOGIN: 'Log in',
  LOGIN_REGISTER: '',
  PASSWORD_FORGOTTEN: '',
  ERROR_LOGIN: '',
  NOT_REGISTERED_MESSAGE: '',
  REGISTER: '',
  PERSONALIZATION_TITLE: '',
  PERSONALIZATION_SUBTITLE: '',
  PERSONALIZATION_SELECT_TIMEZONE: '',
  PERSONALIZATION_SELECT_LANGUAGE: '',
  PERSONALIZATION_PRIVACY: '',
  CONFIRM: '',
  SKIP: '',
  PERCENT_COMPATIBLE: '',
  AUDIENCE_RATING: '',
  SPEAKER_RATING: '',
  COURSE_AVAILABLE_LANGUAGES: '',
  COURSE_AVAILABLE_SUBTITLES_LANGUAGES: '',
  LOGIN_TO_VIEW: '',
  NUMBER_OF_COURSES: '',
  BROWSE_COURSE_BY_OBJECTIVE: '',
  VIEW_ALL_BRANDS_LABEL: '',
  EXPLORE_CHANNELS: '',
  WHAT_LEARN_TODAY: '',
  WHAT_LOOKING_FOR: '',
  VIEW_MORE: '',
  VIEW_LESS: '',
  N_RESULTS_FOR_YOUR_SEARCH: '',
  DO_YOU_CONFIRM_BOOKING: '',
  YES_CONFIRM_BOOKING: '',
  NO_CONFIRM_BOOKING: '',
  DO_YOU_CONFIRM_CANCEL_BOOKING: '',
  YES_CONFIRM_CANCEL_BOOKING: '',
  LEARNING_BITES: '',
  TODAY_S_PILL: '',
  TAKE_PERSONALIZATION_QUIZ_TITLE: '',
  TAKE_PERSONALIZATION_QUIZ: '',
  TAKE_PERSONALIZATION_DISCLAIMER_TEXT: '',
  RECOMMENDED_TO_YOU: '',
  REFINE_PERSONALIZATION: '',
  TO_BE_CONTINUED: '', //   TO_BE_CONTINUED: "Continue learning",
  NOTIFICATIONS_PAGE: '',
  PRIVACY_PAGE: '',
  LIVE_THE_BEST_EXPERIENCE: '',
  TOP_RESULTS_FOR: '',
  ZERO_RESULTS_FOR: '',
  NO_SEARCH_RESULTS_SORRY: '',
  NO_SEARCH_RESULTS_IF_YOU_DIDNT_FIND: '',
  NO_SEARCH_RESULTS_DOUBLE_CHECK: '',
  NO_SEARCH_RESULTS_CHANGE_QUERY: '',
  NO_SEARCH_RESULTS_BE_LESS_SPECIFIC: '',
  NO_SEARCH_RESULTS_DID_YOU_MEAN: '',
  TRY_BROWSING_MOST_LIKED_CONTENT: '',
  HOW_SATISFIED_WERE_YOU: '',
  SEND_LABEL: '',
  WERE_THE_CONTENT_CLEARLY_PRESENTED: '',
  STRONGLY_AGREE: '',
  STRONGLY_DISAGREE: '',
  HOW_MUCH_YOU_USE_IT: '',
  WOULD_YOU_SUGGEST_IT: '',
  HOW_COULD_WE_IMPROVE: '',
  SUBMIT_SURVEY: '',
  SESSION_SEATS_LEFT: '',
  SESSION_NO_SEATS_LEFT: '',
  ACTIVITY_BUTTON_VIEW_RECORDING: '',
  ACTIVITY_BUTTON_CHOOSE_SESSION: '',
  ACTIVITY_BUTTON_CANCEL_BOOKING: '',
  ACTIVITY_BUTTON_CHOOSE_FORMAT: '',
  ACTIVITY_BUTTON_CALENDAR: '',
  CANCEL: '',
  SEARCH_YOU_MIGHT_BE_INTERESTED_IN: '',
  SEARCH_PREVIOUSLY_SEARCHED: '',
  YOU_MAY_ALSO_LIKE: '',
  MORE_FROM: '',
  SORT_BY_LABEL: 'Sort by',
  ALL_COURSES: '',
  ALL_CHANNELS: '',
  FOLLOW: '',
  SORT_BY_LEARNING_SEQUENCE: '',
  TITLE_SORT_BY_LABEL: '',
  PLP_ALL_LP_TITLE: '',
  PLP_ALL_LP_DESCR: '',
  PROFILE_SETTINGS_TITLE: '',
  NEW_PASSWORD: '',
  CONFIRM_PASSWORD: '',
  SAVE_CHANGES: '',
  INFO_SUCCESSFULLY_SAVED: '',
  EMAIL_PLACEHOLDER: '',
  NAME_PLACEHOLDER: '',
  SURNAME_PLACEHOLDER: '',
  COUNTRY_PLACEHOLDER: '',
  ROLE_PLACEHOLDER: '',
  PASSWORD_NOT_MATCH: '',
  MIN_LENGTH_VALIDATION: '',
  REQUIRED_VALIDATION: '',
  AGREEMENT: '',
  AGREEMENT_VALIDATION: '',
  TIME_SPENT_ON_TRAINING: '',
  VS_LAST_MONTH: '',
  TRENDING_LABEL: '',
  RATE_LESSON: '',
  RATE_COURSE: '',
  LOGIN_USERNAME_PLACEHOLDER: '',
  LOGIN_PASS_PLACEHOLDER: '',
  LOGIN_CTA: '',
  LOGIN_BANNER_TEXT: '',
  LOGIN_SIGNUP_PAGE_NAME_LABEL: '',
  CONTACT_SUPPORT_PAGE_NAME_LABEL: '',
  PRIVACY_PAGE_NAME_LABEL: '',
  LOGIN_EMAIL_NOT_MATCH: '',
  LOGIN_PASS_INCORRECT: '',
  SIGNUP_PAGE_NAME_LABEL: '',
  SIGNUP_PLEASENOTE: '',
  ROLE_OWNER: '',
  ROLE_EYECARE_PRO: '',
  ROLE_SALES: '',
  ROLE_EYECARE_STUDENT: '',
  ROLE_CONSUMER: '',
  ROLE_OTHER: '',
  PURCHASE_QUESTION: '',
  PURCHASE_ANSWER_FRAMES: '',
  PURCHASE_ANSWER_LENSES: '',
  PURCHASE_ANSWER_INSTRUMENTS: '',
  PURCHASE_ANSWER_NO: '',
  LUX_CUSTOMER_CODE: '',
  ESS_CUSTOMER_CODE: '',
  COMPANY_NAME: '',
  PASS_PLACEHOLDER: '',
  REPEAT_PASS_PLACEHOLDER: '',
  ACCEPT_TERMS_COND: '',
  SIGNUP_CTA: '',
  NEED_HELP: '',
  CODE_NOT_VALID: '',
  EMAIL_IN_USE: '',
  EMAIL_IN_USE_DESCR: '',
  REGISTRATION_LOADER_TITLE: '',
  REGISTRATION_LOADER_DESC: '',
  REGISTRATION_SUCCESS_TITLE: '',
  REGISTRATION_SUCCESS_DESC: '',
  GO_HOMEPAGE_CTA: '',
  EMAIL_CONFIRM_TITLE: '',
  EMAIL_CONFIRM_DESC: '',
  EMAIL_CONFIRM_CTA: '',
  WANT_EXPERIENCE_TAILORED: '',
  EMAIL_CONFIRM_QUIZ: '',
  TAKE_QUIZ_CTA: '',
  EMAIL_CONFIRM_FOOTER_1: '',
  EMAIL_CONFIRM_FOOTER_2: '',
  EMAIL_CONFIRM_FOOTER_3: '',
  EMAIL_PASS_TITLE: '',
  EMAIL_PASS_DESC: '',
  EMAIL_PASS_PHONE: '',
  EMAIL_PASS_EMAIL: '',
  SELECT_OPTION: '',
  RESET_PASS: '',
  RESET_PASS_TITLE: '',
  RESET_PASS_DESCR: '',
  EMAIL_PASS_RESET_TITLE: '',
  EMAIL_PASS_RESET_DESC: '',
  EMAIL_PASS_RESET_CTA: '',
  EMAIL_PASS_RESET_FOOTER: '',
  REQ_NEW_PASS_RECEIVED_TITLE: '',
  REQ_NEW_PASS_RECEIVED_DESC: '',
  PASS_RESET_SUCCESS_ALL: '',
  PASS_RESET_SUCCESS_SUB: '',
  NO_ACCOUNT_REGISTERED: '',
  CATALOGUE_TITLE: '',
  DOWNLOAD_ALL: '',
  ALL: '',
  PRODUCT: '',
  SKILLS: '',
  LATEST_FROM: '',
  VIEW_ALL: '',
  COMPATIBILITY_LABEL: '',
  DURATION_LESS_5: '',
  DURATION_5_10: '',
  DURATION_10_20: '',
  DURATION_MORE_20: '',
  SPEAKER: '',
  SCHEDULE: '',
  SCHEDULE_TODAY: '',
  SCHEDULE_TOMORROW: '',
  SCHEDULE_NEXT_WEEK: '',
  SCHEDULE_NEXT_MONTH: '',
  LOCATION: '',
  AVAILABLE_FOR_DOWNLOAD: '',
  HOMEWORK_BEFORE: '',
  HOMEWORK_AFTER: '',
  COURSE_DIFFERENT_FORMATS: '',
  LIVE_VIDEO_ON: '',
  RATE_LESSON_TITLE: '',
  RATE_COURSE_TITLE: '',
  OVERALL_SCORE: '',
  COMMENTS_SUGGESTIONS: '',
  BROWSE_BY_BRAND: '',
  BROWSE_BY_CHANNEL: '',
  CT_SUP_CONTACT: '',
  CONTACT_MESSAGE: '',
  CONTACT_EMAIL: '',
  CONTACT_WRITE_MESSAGE: '',
  SEND: '',
  CONTACT_PHONE: '',
  BASED_IN: '',
  FAQ_EXTENDED: '',
  TRENDING_TOPICS: '',
  MAINTENANCE_1: '',
  MAINTENANCE_2: '',
  MAINTENANCE_UNSCHEDULED_1: '',
  MAINTENANCE_UNSCHEDULED_2: '',
  COURTESY_1: '',
  COURTESY_2: '',
  COURTESY_BROWSERS: '',
  OFFLINE_BANNER: '',
  OFFLINE_DESC_1: '',
  OFFLINE_DESC_2: '',
  OFFLINE_LEARNING_EXPERIENCE: '',
  MY_DOWNLOADS: '',
  OFFLINE_BANNER_UPPERCASE: '',
  OFFLINE_BROWSE: '',
  ONCE_BACK_ONLINE: '',
  DOWNLOAD_INTERRUPTED: '',
  OLDER_VERSION_DOWNLOADED: '',
  REPLACE: '',
  SURE_REMOVE_COURSE: '',
  REMOVE: '',
  BACK_ONLINE: '',
  SYNCING_ACTIONS: '',
  DOWNLOADING_SIZE: '',
  STOP_DOWNLOAD: '',
  PREVIOUSLY_SEARCHED: '',
  BROWSE_BY_BEST_CONTENT: '',
  SEARCH_RESULTS_FOR: '',
  SEARCH_INSTEAD: '',
  EN: '',
  ITA: '',
  FR: '',
  ES: '',
  POR: '',
  SC: '',
  TOGETHER_GROW: '',
  LOGINSIGNUP: '',
  NEW_IN: '',
  BROWSE_OUR_CATALOGUE: '',
  LEARN_FROM_EXPERTS: '',
  X_ARE_LEARNING: '',
  JOIN_COMMUNITY: '',
  HERE_LATEST: '',
  DOWNLOAD_APP: '',
  DOWNLOAD_APP_EXPERIENCE: '',
  TAKE_SHORT_QUIZ: '',
  VIEW_ALL_COURSES: '',
  TRENDING_CHANNELS: '',
  PERSONALIZE: '',
  TODOS: '',
  TODOS_DESCR: '',
  MY_CHANNELS: '',
  NAV_HOME: '',
  NAV_CATALOGUE: '',
  NAV_LOGINSIGNUP: '',
  NAV_PROFILE: '',
  NAV_CONTACT_SUPPORT: '',
  BROWSE_LATEST_CONTENT: '',
  SIGNUP_NOW: '',
  LOGIN_SIGNUP_TO_VIEW: '',
  WELCOME: '',
  QUICK_QUESTIONS: '',
  YOUR_TIMEZONE: '',
  SELECT_LANGUAGE: '',
  ACCEPT_TERMS_CONDITIONS: '',
  WHEN_TIME_LEARNING: '',
  WHEN_TIME_LEARNING_RES_WORK: '',
  WHEN_TIME_LEARNING_RES_HOME: '',
  WHEN_TIME_LEARNING_RES_GO: '',
  FAVORITE_BRANDS: '',
  INTERESTED_LEARNING: '',
  DOWNLOAD: '',
  PROFILE_PAGE: '',
  PROFILE_TO_DO: '',
  PROFILE_UPCOMING_EVENTS: '',
  PROFILE_MY_PROGRESS: '',
  PROFILE_MY_TEAM: '',
  PROFILE_MAIN_FIGURES: '',
  PROFILE_SETTINGS: '',
  TOTAL_CREDITS: '',
  MY_ACCOMPLISHMENTS: '',
  TIME_SPENT_TRAINING: ' ',
  AWARDED_BY: '',
  EXPIRES: '',
  FOR_ATTENDING: '',
  AWARDED_TO: '',
  CERTIFICATE_OF_ATTENDANCE: '',
  FOR_SUCCESSFULLY: '',
  CERTIFICATE_OF_COMPLETION: '',
  SEARCH_TEAM_MEMBER: '',
  SELECT_A_TEAM_MEMBER: '',
  NAME_PLACE_SCORING: '',
  TOP_NUM: '',
  COURSE_OVERDUE: '',
  MISSED_CLASS: '',
  SELECT_TEAM_MEMBER: '',
  SORT_BY_NAME: '',
  COMPLETIONS_SATURATION: '',
  YOUR_FIGURES: '',
  DOWNLOAD_PDF: '',
  DOWNLOAD_XLS: '',
  LAST_UPDATE: '',
  MONTHLY: '',
  BI_WEEKLY: '',
  WEEKLY: '',
  DAILY: ' ',
  SMS: '',
  APP_NOTIFICATIONS: '',
  WHAT_CHANNEL_WOULD: '',
  PLATFORM_NEWS: '',
  LEADERBOARD: '',
  EVENTS: '',
  NEW_CONTENTS: '',
  ASSIGNMENT: '',
  WHAT_DO_YOU_WANT: '',
  UPDATE_YOUR_NOTIFICATION: '',
  NOTIFICATION_SETTINGS: '',
  YOUR_PERSONAL_INFORMATION: '',
  BY_CLICKING_HERE: '',
  PERSONAL_INFORMATION: '',
  UNFOLLOW: '',
  BY_FOLLOWING_A_CHANNEL: '',
  SHOW_MY_POSITION: '',
  EDIT: '',
  BY_EMAIL: '',
  CHANNELS: '',
  CONTENT_PERSONALIZATION: '',
  PERSONALIZE_COLORS: '',
  DARK_MODE: '',
  GRADIENT_COLOR: '',
  NUM_RESULTS_FOR: '',
  DOWNLOAD_LEARNING: '',
  MY_CERTIFICATES: '',
  YOU_COMPLETED_ALL: '',
  ALL_DONE_FOR_THE: '',
  LOAD_NUM_MORE: '',
  DASHBOARD: '',
  LOG_OUT: '',
  BUTTON_INTRANET_MENU: '',
  BUTTON_INTRANET_MENU2: '',
  MY_PROFILE: '',
  RELOAD: '',
  LEADERBOARD_TOP: '',
  YOUR_TEAM_MEMBER: '',
  LEADERBOARD_UPDATE: '',
  GO_TO_COURSE: '',
  GO_TO_COURSE_MULTIACTIVITY: '',
  NAME_HAS_BEEN: '',
  NEW_RELEASE: '',
  YOU_COMPLETED_NAME: '',
  FIND_INSPIRATION: '',
  THE_DOWNLOAD_OF: '',
  DOWNLOAD_FAILED: '',
  MY_TO_DO_S: '',
  BACK_ONLINE_SYNC: '',
  PERSONALIZATION: '',
  COMPLETED_ON_SUGGESTION: '',
  COURSE_SHARED_BY: '',
  NEW_LANDING_PAGE: '',
  DOWNLOAD_STOP: '',
  DOWNLOAD_COMPLETED: '',
  WISHLIST_CLASS: '',
  WISHLIST_COURSE: '',
  PROMPT_RATING: '',
  MAINTENANCE: '',
  SATURATION: '',
  TEAM_MEMBERS: '',
  SELECT_TEAM_MEMBERS: '',
  REMOVE_FROM_WISHLIST_CONFIRM_MESSAGE: '',
  REMOVE_CONTENT: '',
  EMPTY_WISHLIST_MESSAGE: '',
  DOWNLOAD_LEARNING_PASSPORT: '',
  COMPLETED_ON: '',
  OK: '',
  EMAIL_NOTIFICATIONS: '',
  PERCENT_VS_LAST_MONTH: '',
  CONTACT_US_MESSAGE: '',
  LEGAL_TITLE: '',
  CLASSROOM_DATES: '',
  LUX_CUSTOMER_CODE_FORMAT: '',
  RESET_PASS_NEW_PASS: '',
  RESET_PASS_RETYPE_NEW_PASS: '',
  RESET_PASS_CTA: '',
  ERROR_MIN_NUM_CHARS: '',
  ERROR_REWRITE_PASS: '',
  ERROR_PASS_NOT_MATCH: '',
  ERROR_PASS_FORMAT: '',
  ERROR_PASS_PATTERN: '',
  ERROR_REQUIRED: '',
  I_AM_PRIVATE_USER: '',
  I_AM_ESS_LUX: '',
  ESSLUX_CONTACT_LOCAL: '',
  MYL_KO_REGISTRATION: '',
  RESET_PASS_CONFIRMATION: '',
  COURSE_COMPLETIONS: '',
  PAGE_NUM: '',
  EXIT: '',
  ABANDON: '',
  STAY: '',
  SURE_LEAVE_COURSE: '',
  SURE_LEAVE_COURSE_CREDITS: '',
  PAGE_NOT_FOUND: '',
  COOKIE_BANNER_DESC: '',
  ACCEPT_COOKIES: '',
  MANAGE_COOKIES: '',
  COOKIE_SETTINGS: '',
  COOKIE_SETTINGS_DESC: '',
  ANALYTICS: '',
  ANALYTICS_DESC: '',
  MARKETING: '',
  MARKETING_DESC: '',
  URL_REGISTRATION_EXPIRED: '',
  PREVIEW_BUTTON: '',
  FORGOT_PASS_LUX_CUSTOMER_CODE: '',
  FORGOT_PASS_LUX_CUSTOMER_CODE_FORMAT: '',
  REG_CCC_2_WHITE: '',
  PASS_EXPIRED_POPUP: '',
  ['6S_ct1']: '',
  ['6S_ct2']: '',
  ['6S_ct3']: '',
  A0_ct1: '',
  A0_ct2: '',
  A0_ct3: '',
  AN_ct1: '',
  AN_ct2: '',
  AN_ct3: '',
  BO_ct1: '',
  BO_ct2: '',
  BO_ct3: '',
  OO_ct1: '',
  OO_ct2: '',
  OO_ct3: '',
  OO_ct4: '',
  OO_ct5: '',
  OO_ct6: '',
  OO_ct7: '',
  OA_ct1: '',
  OA_ct2: '',
  OA_ct3: '',
  OA_ct4: '',
  OA_ct5: '',
  OA_ct6: '',
  OP_ct1: '',
  OP_ct2: '',
  OP_ct3: '',
  PO_ct1: '',
  PO_ct2: '',
  PO_ct3: '',
  RB_ct1: '',
  RB_ct2: '',
  RB_ct3: '',
  RB_ct4: '',
  RB_ct5: '',
  VO_ct1: '',
  VO_ct2: '',
  VO_ct3: '',
  SINGLE_COURSE_OVERDUE: '',
  SINGLE_LESSON_OVERDUE: '',
  SINGLE_MISSED_CLASS: '',
  SAVE: '',
  NEW_RELEASES: '',
  YES: '',
  REGISTER_NOTE: '',
  ALREADY_ACCOUNT: '',
  PURCHASE_QUESTION_1: '',
  PURCHASE_QUESTION_2: '',
  PURCHASE_ITEM_FRAMES: '',
  PURCHASE_ITEM_LENSES: '',
  PURCHASE_ITEM_INSTRUMENTS: '',
  MODAL_ALREADY_ACCOUNT_TITLE: '',
  MODAL_ALREADY_ACCOUNT_DESCRIPTION: '',
  PLEASE_CONTACT_MESSAGE: '',
  COOKIES_SETTINGS: '',
  ACTIVE_USER_SUCCESS: '',
  COOKIE_POLICY: '',
  PRIVACY_POLICY: '',
  MEMBERS: '',
  FILTER_LABEL: '',
  CREDIT_LABEL: '',
  LEAVE: '',
  COMPLETION: '',
  HOT_CHANNELS: '',
  TEAM_MEMBER_LEADERBOARD_OUT: '',
  TEACHER_BO: '',
  LP_BE_INSPIRED: '',
  DOC_TEAM_FILENAME: '',
  LEARNINGPASSPORT_FILENAME: '',
  TEAM_MEMBER: '',
  MEMBER: '',
  RECOMMENDED_TO_YOU_DESCR: '',
  BROWSE_COURSE_BY_OBJECTIVE_DESCR: '',
  LP_CAROUSEL_FOUNDATION: '',
  LP_CAROUSEL_ADVANCED: '',
  LP_CAROUSEL_PROFESSIONAL: '',
  LP_CAROUSEL_MASTER: '',
  LP_CAROUSEL_MASTERCLASS: '',
  LP_CAROUSEL_FOUNDATION_DESCR: '',
  LP_CAROUSEL_ADVANCED_DESCR: '',
  LP_CAROUSEL_PROFESSIONAL_DESCR: '',
  LP_CAROUSEL_MASTER_DESCR: '',
  LP_CAROUSEL_MASTERCLASS_DESCR: '',
  CARD_EXPLORE_CHANNEL: '',
  VIEW_ALL_RESULTS: '',
  BRAND_EYEWEAR_LABEL: '',
  VISIT_HOWTO_SECTION: '',
  HOWTO_SECTION_LABEL: '',
  ONBOARDING_MODAL_TITLE: '',
  ONBOARDING_MODAL_DESCR: '',
  ONBOARDING_MODAL_SKIP: '',
  FILTER_CTYPE_INTERACTIVE: '',
  FILTER_CTYPE_VIDEO: '',
  FILTER_CTYPE_PODCAST: '',
  FILTER_CTYPE_PDF: '',
  EXPLORE_BY_COURSE: '',
  SEE_SOON_APP: '',
  SEE_SOON_APP_DESCR: '',
  VISIT_WEB_VERSION: '',
  FOOTER_MESSAGE: '',
  CREDITS_OVERVIEW: '',
  DELETE_PROFILE: '',
  MYL_KO_RESET_PASSWORD: '',
  DELETE_PROFILE_SUCCESS_TITLE: '',
  DELETE_PROFILE_SUCCESS_DESCR: '',
  DELETE_PROFILE_DESCR: '',
  TIME_SPENT_ON_TRAINING_TEAM: '',
  I_AM_ESSILOR_USER: '',
  WHATS_UP_NEXT_LABEL: '',
  PART_OF_LABEL: '',
  LESSON_PART_OF_LABEL: '',
  COURSE_PART_OF_LABEL: '',
  LECTURES_IN_COURSE: '',
  DETAILS_LABEL: '',
  THANKS_FEEDBACK: '',
  COURSE_TRAILER: '',
  RETAKE_LABEL: '',

  ENROLL_LABEL: '',
  DISENROLL_LABEL: '',
  BOOK_LABEL: '',
  ENROLL_AFTER_COMPLETING: '',
  SAVE_CALENDAR: '',
  NOTIFY_ME: '',
  SESSION_NOT_RECORDED: '',
  RECORDING_AVAILABLE: '',
  CERTIFICATE_AVAILABLE: 'Certificate available',
  RECORDING_AVAILABLE_ABBR: '',
  RECORDING_SOON_AVAILABLE: '',
  RECORDING_SOON_AVAILABLE_ABBR: '',
  LIVESTREAM_NOW: '',
  LIVESTREAM_IN: '',
  LIVESTREAM_ON: '',
  LIVESTREAM_ON_ABBR: '',
  LIVESTREAM_ENDING_IN: '',
  SPEAKERS_LABEL: '',
  WILL_BE_NOTIFIED_RECORDING_LIVESTREAM: '',
  NOTIFY_ME_LIVE_STARTING: '',
  ARCHIVE_LABEL: '',
  UPCOMING_LIVESTREAM: '',
  SORT_BY_LIVESTREAM_DATE: '',
  SEE_MORE: '',
  SEE_LESS: '',
  LIVESTREAM_RECORDING_AVAILABLE: '',
  SURE_LEAVE_NO_COMPLETION: '',
  ERROR_PASS_FORMAT2: '',
  FILTER_CTYPE_MULTIACTIVITY: '',
  ACTIVITY_VIDEO: '',
  ACTIVITY_AUDIO: '',
  ACTIVITY_PDF: '',
  ACTIVITY_SCORM: '',
  PREVIEW_BUTTON_MOBILE: '',
  COURSE_TRAILER_MOBILE: '',
  TOC_TITLE: '',
  EMAIL_INVALID: '',
  EMAIL_STUDENT_INVALID: '',
  MY_LEONARDO: '',
  PASSWORD_REQUIREMENT_1: '',
  PASSWORD_REQUIREMENT_2: '',
  PASSWORD_REQUIREMENT_3: '',
  PASSWORD_REQUIREMENT_4: '',
  PASSWORD_REQUIREMENT_5: '',
  PASSWORD_REQUIREMENT_6: '',
  PASSWORD_REQUIREMENT_7: '',
  DO_NOT_SELL_PERSONAL_INFO: '',
  SELECT_COUNTRY: '',
  US_CANADA_COUNTRY: '',
  OTHER_COUNTRIES: '',
  FAQ_EXTENDED_ENUM: '',
  REFUSE: '',
  ACCEPT_ALL_COOKIES: '',
  PRICE_DES_LABEL: '',
  PRICE_ASC_LABEL: '',
  NO_UPCOMING_EVENTS: '',
  BROWSE_CATALOG_MORE_CONTENT: '',
  HOT_CONTENT: '',
  CONTINUE_WATCHING: '',
  CONTINUE_WATCHING_DESCR: '',
  RECENTLY_ADDED: '',
  RECENTLY_ADDED_DESCR: '',
  TRAINING_COURSES: '',
  TRAINING_COURSES_DESCR: '',
  CONTINUING_EDUCATION: '',
  CONTINUING_EDUCATION_DESCR: '',
  TOP_10: '',
  TOP_10_DESCR: '',
  TRENDING_NOW: '',
  TRENDING_NOW_DESCR: '',
  NEW_LENSES_GENERATION: '',
  NEW_LENSES_GENERATION_DESCR: '',
  SKILL_OF_THE_MONTH: '',
  SKILL_OF_THE_MONTH_DESCR: '',
  VIDEOS_UNDER_MINUTES: '',
  VIDEOS_UNDER_MINUTES_DESCR: '',
  OUR_PODCASTS: '',
  OUR_PODCASTS_DESCR: '',
  MOST_LIKED: '',
  MOST_LIKED_DESCR: '',
  COMING_SOON: '',
  COMING_SOON_DESCR: '',
  HIGHLIGHTED_LABEL: '',
  HIGHLIGHTED_LABEL_DESCR: '',
  HERO_BANNER_FALLBACK_TITLE: '',
  HERO_BANNER_FALLBACK_DESCR: '',
  POWERED_BY: '',
  RECOGNIZED_BY: '',
  PREVIEW_BANNER_TEXT: '',
  CMS_PREVIEW_BANNER_TEXT: 'You are currently on the CMS PREVIEW MODE',
  PREVIEW_BANNER_EXIT: '',
  PREVIEW_BANNER_BACK_TO_BACKOFFICE: '',
  PAST_EVENTS: '',
  SUNDAY: '',
  MONDAY: '',
  TUESDAY: '',
  WEDNESDAY: '',
  THURSDAY: '',
  FRIDAY: '',
  SATURDAY: '',
  TODAY: '',
  IN_15_MIN: '',
  NO_RESULTS: '',
  FILTER_DATE: '',
  LAST_X_DAYS: '',
  NEXT_X_DAYS: '',
  SEARCH_IN: '',
  FROM_TO_ORDER_DATE: '',
  CUSTOMIZE: '',
  DOC_TITLE_HOME: '',
  DOC_TITLE_CATALOGUE: '',
  DOC_TITLE_CONTACT_SUPPORT: '',
  MY_FAVORITES_TITLE: '',
  MY_LEONARDO_DESC: '',
  CUSTOMIZE_MY_LEONARDO_DESCR: '',
  CUSTOMIZE_MY_LEONARDO_CTA: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_TITLE: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_DESCR: '',
  SEARCH_BY_KEYWORD: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_RESET: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_FOLLOW: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_SUGGESTED_FOR_YOU: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_TOPICS: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_BRANDS: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_SEE_ALL: '',
  CUSTOMIZE_MY_LEONARDO_MODAL_SEE_LESS: '',
  CHOOSE_TOPICS_TO_FOLLOW: '',
  MY_NEXT_EVENTS: '',
  CARD_UPCOMING_SESSION_CALENDAR: '',
  CODE_LABEL: '',
  COMING_SOON_LANG_TITLE: '',
  ADD_TO_HOMESCREEN_CTA: '',
  ADD_TO_HOMESCREEN_CTA_DESCRIPTION: '',
  ADD_TO_HOMESCREEN_MODAL_TITLE: '',
  ADD_TO_HOMESCREEN_MODAL_DESCR1: '',
  ADD_TO_HOMESCREEN_MODAL_DESCR2: '',
  ADD_TO_HOMESCREEN_MODAL_DESCR3: '',
  ADDITIONAL_CHANNEL_INFOS: '',
  EXPLORE_ALL_CHANNEL_INFO: '',
  EXPLORE_ALL_CHANNEL_INFO_EVENTS: '',
  EXPLORE_ALL_CHANNEL_INFO_COURSES: '',
  EXPLORE_ALL_CHANNEL_INFO_LESSONS: '',
  ADD_TO_HOMESCREEN_BUTTON_ADD: 'ADD',
  SEARCH_PLACEHOLDER: '',
  CATALOGUE_DESCR: '',
  AREAS_OF_EXPERTISE: '',
  ALL_LESSONS_DESCR: '',
  FIND_PERFECT_COURSE_YOU: '',
  FIND_PERFECT_COURSE_YOU_DESCR: '',
  BROWSE_OUR_COURSES: '',
  OUR_TEACHING_METHODOLOGY: '',
  TEACHING_METHODOLOGY_DESCR: '',
  TEACHING_METHODOLOGY_1: '',
  TEACHING_METHODOLOGY_2: '',
  TEACHING_METHODOLOGY_3: '',
  TEACHING_METHODOLOGY_4: '',
  TEACHING_METHODOLOGY_5: '',
  BACK_TO_CATALOG: '',
  PROGRESS_SAVED_MODAL_TITLE: '',
  PROGRESS_SAVED_MODAL_DESCR: '',
  RESUME_ACTIVITY: '',
  ACTIVITY_COMPLETED_MODAL_TITLE: '',
  GO_NEXT_ACTIVITY: '',
  COURSE_COMPLETED_MODAL_TITLE: '',
  NEXT_ACTIVITY: '',
  COMPLETED: '',
  RATIO_ACTIVITIES_COMPLETED: '',
  ACTIVITIES_TITLE: '',
  L1_CARD_DESCRIPTION_SEARCH: '',
  FILTER_COMPLEXITY_CATEGORY_COURSES: '',
  FILTER_COMPLEXITY_CATEGORY_LESSONS: '',
  FILTER_COMPLEXITY_CATEGORY_EVENTS: '',
  YEAR: '',
  PLAY_VIDEO: '',
  ONBOARDINGCOURSE_DOC_TITLE: '',
  ONBOARDINGCOURSE_NO_COURSE: '',
  ONBOARDINGCOURSE_DUE_BY: '',
  ONBOARDINGCOURSE_ONLINE_TRAINING: '',
  ONBOARDINGCOURSE_ON_THE_JOB_TRAINING: '',
  ONBOARDINGCOURSE_VIRTUAL_CLASSROOM: '',
  ONBOARDINGCOURSE_CREDITS: '',
  CTA_DOWNLOAD_CERTIFICATE: '',
  CTA_SHARE_CERTIFICATE: '',
  CTA_SHARE: '',
  CONGRATULATIONS: '',
  COMPLETED_CONTENT: '',
  ENABLE_LINKEDIN_ALERT: '',
  ONBOARDINGCOURSE_TAB_OVERVIEW: '',
  ONBOARDINGCOURSE_TAB_LESSONS: '',
  ONBOARDINGCOURSE_TAB_ACTIVITIES: '',
  // ONBOARDINGCOURSE_START_NOW: "Start now to complete the onboarding within the due date",
  ONBOARDINGCOURSE_GRAPH_LESSON_STATUS: '',
  ONBOARDINGCOURSE_GRAPH_VC_STATUS: '',
  ONBOARDINGCOURSE_GRAPH_COMPLETED: '',
  ONBOARDINGCOURSE_GRAPH_INPROGRESS: '',
  ONBOARDINGCOURSE_GRAPH_TODO: '',
  ONBOARDINGCOURSE_GRAPH_BOOKED: '',
  ONBOARDINGCOURSE_GRAPH_VIEW_VC: '',
  ONBOARDINGCOURSE_OVERVIEW_HOW_WILL_YOU_LEARN: '',
  ONBOARDINGCOURSE_OVERVIEW_HOW_WILL_YOU_LEARN_DESC: '',
  ONBOARDINGCOURSE_OVERVIEW_ONLINE_TRAINING: '',
  ONBOARDINGCOURSE_OVERVIEW_ONLINE_TRAINING_DESC: '',
  ONBOARDINGCOURSE_OVERVIEW_ON_THE_JOB_TRAINING: '',
  ONBOARDINGCOURSE_OVERVIEW_ON_THE_JOB_TRAINING_DESC: '',
  ONBOARDINGCOURSE_OVERVIEW_VC: '',
  ONBOARDINGCOURSE_OVERVIEW_VC_DESC: '',
  // ONBOARDINGCOURSE_OVERVIEW_WHAT_CAN_YOU_EXPECT: "What can you expect?",
  ONBOARDINGCOURSE_LESSONS_NUM: '',
  MYTEAM_FILTER_BY: '',
  MYTEAM_PEOPLE_IN_ONBOARDING: '',
  MANDATORY_LEARNING: 'Mandatory learning',
  MYTEAM_NAME_SURNAME: '',
  MYTEAM_SURNAME: '',
  MYTEAM_TIME_SPENT_LEARNING: '',
  MYTEAM_TEAM_LEARNING_ACTIVITY: '',
  MYTEAM_YOU_HAVE_TEAM_MEMBERS: '',
  MYTEAM_CREDITS_EARNED: '',
  MYTEAM_TOTAL_TIME_SPENT_LEARNING: '',
  MYTEAM_REPORT: '',
  MYTEAM_MEMBER_ONBOARDING_TAB_TITLE: '',
  MYTEAM_MEMBER_ONBOARDING_TAB_TITLE_SHORT: '',
  MYTEAM_MEMBER_OVERDUE_TAB_TITLE: '',
  MYTEAM_MEMBER_COMPLIANCE: '',
  MYTEAM_MEMBER_OVERDUE_LESSON_NAME: '',
  MYTEAM_MEMBER_OVERDUE_LESSON_TYPE: '',
  MYTEAM_MEMBER_OVERDUE_DUE_DATE: '',
  MYTEAM_MEMBER_OVERDUE_DURATION: '',
  MYTEAM_MEMBER_LESSON_STATUS_TODO: '',
  MYTEAM_MEMBER_LESSON_STATUS_INPROGRESS: '',
  MYTEAM_MEMBER_LESSON_STATUS_COMPLETED: '',
  DOC_TEAM_SINGLE_MEMBER_FILENAME: '',
  MYTEAM_PDF_TITLE_MIN: '',
  MYTEAM_PDF_TITLE_LESSON_NAME: '',
  MYTEAM_PDF_TITLE_COMPLIANCE: '',
  MYTEAM_PDF_TITLE_STATUS: '',
  MYTEAM_PDF_STATUS_TODO: '',
  MYTEAM_PDF_STATUS_INPROGRESS: '',
  MYTEAM_PDF_STATUS_COMPLETED: '',
  ONBOARDING_CELEBRATION_MODAL_ROW1: '',
  ONBOARDING_CELEBRATION_MODAL_ROW2: '',
  ONBOARDING_CELEBRATION_MODAL_BUTTON_THANKS: '',
  ONBOARDING_CELEBRATION_MODAL_BUTTON_DOWNLOAD: '',
  ONBOARDING_CELEBRATION_PDF_FILENAME: '',
  ONBOARDING_CERTFICATION_LEARNING: '',
  ONBOARDING_CERTFICATION_LEARNING_AND_VIRTUAL_CLASS: '',
  EDUCATIONAL_PATHS_SECTION_TITLE: '',
  EDUCATIONAL_PATHS_SECTION_DESCR: '',
  ONBOARDINGCOURSE_EXPIRATION_DATE: '',
  WATCH_VIDEO: '',
  MYLEO_WELCOME: '',
  ROPC_RESET_PASSWORD: '',
  ROPC_POSTLOGIN_PASSWORD_SUCCESS: '',
  ROPC_POSTLOGIN_PASSWORD_FAILURE: '',
  PASS_RESET_FAILURE_ALL: '',
  SEE_ALL_LABEL: '',
  DISCOVER_LABEL: '',
  ICS_SUBJECT: '',
  ICS_LOCATION: '',
  ICS_BOOKED_VC: '',
  ICS_BOOKED_F2F: '',
  ICS_TAKE_PLACE_LIVESTREAM: '',
  ICS_TAKE_PLACE_VC: '',
  ICS_JOIN_CLICKING_HERE: '',
  RECORDING_AVAILABLE_UNTIL: '',
  DO_YOU_CONFIRM_BOOKING_TITLE: '',
  DO_YOU_CONFIRM_CANCEL_BOOKING_TITLE: '',
  ONBOARDING_CELEBRATION_MODAL_TITLE: '',
  EDUCATIONAL_PATHS_TITLE: '',
  EDUCATIONAL_PATHS_DESCR: '',
  EDUCATIONAL_PATHS_NO_PROGRAM: '',
  EXPERT: '',
  AVAILABLE: '',
  RATING_MODEL_COMPLETATION_ALERT: '',
  RATING_MODAL_COMPLETATION_MORE_THAN_FIVE_ALERT: '',
  BACK_HEADER: '',
  PROGRAMS_MENU_TITLE: '',
  PROGRAMS_MENU_DESCR: '',
  PROGRAMS_MENU_CTA: '',
  START_LEARNING: '',
  CONTINUE_LEARNING: '',
  AREAS_OF_EXPERTISE_DESCR: '',
  AREAS_OF_EXPERTISE_CARD_CTA: '',
  NOT_FOUND_SUBTITLE: '',
  NOT_FOUND_TITLE: '',
  NOT_FOUND_DESCR: '',
  FOOTER_ABOUT_US: '',
  FOOTER_USEFUL_LINKS: '',
  FOOTER_HELP_INFO: '',
  FOOTER_INTRANET: '',
  FOOTER_INTRANET_DESCR: '',
  FOOTER_INTRANET_CTA: '',
  FOOTER_SOCIAL: '',
  BROWSE_BY_TOPICS_CAROUSEL_TITLE: '',
  BROWSE_BY_TOPICS_CAROUSEL_DESCR: '',
  AVAILABLE_SOON: '',
  //labels for master
  ASSIGNMENT_TITLE: '',
  ASSIGNMENT_DESCR: '',
  ASSIGNMENT_CTA: '',
  ONE_TO_ONE_TITLE: '',
  ONE_TO_ONE_DESCR: '',
  ONE_TO_ONE_CTA: '',
  SUPPORT_MATERIAL_TITLE: '',
  SUPPORT_MATERIAL_DESCR: '',
  SUPPORT_MATERIAL_CTA: '',
  ONBOARDING_ONETOONE_ICON_TITLE: '',
  ONBOARDING_ONETOONE_ICON_DESCR: '',
  ONBOARDING_ASSIGNMENT_ICON_TITLE: '',
  ONBOARDING_ASSIGNMENT_ICON_DESCR: '',
  ONBOARDINGCOURSE_TAB_OVERVIEW_WHAT_WILL_YOU_LEARN: '',
  ONBOARDINGCOURSE_TAB_TEACHERS: '',
  ONBOARDINGCOURSE_TAB_TEACHERS_LESSONS: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_BUTTON_CANCEL: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_FIELD_FIRSTNAME: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_FIELD_LASTNAME: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_FIELD_EMAIL: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_FIELD_PHONE: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_FIELD_DESCRIPTION: '',
  ONBOARDINGCOURSE_FORM_TITLE_MESSAGE_SENT: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_SERVICE_DESCRIPTION: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_SERVICE_MESSAGE_SENT: '',
  ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_SERVICE_MESSAGE_SENT_BTN: '',
  ONBOARDINGCOURSE_FORM_REQUEST_JOIN_DESCRIPTION: '',
  MASTER_GO_TO_LESSON: '',
  MASTER_WILL_START_SOON_ROW1: '',
  MASTER_WILL_START_SOON_ROW2: '',
  DISCOVER_MORE: '',
  MASTER_BANNER_HP_LESSONS_WILL_AVAILABLE: '',
  TRENDING_SEARCHES: '',
  VIEW_ALL_CATALOGUE: '',
  SCHOOLNAME_PLACEHOLDER: '',
  GRADUATION_PLACEHOLDER: '',
  STATE_OF_RESIDENCE_PLACEHOLDER: '',
  ZIPCODE_PLACEHOLDER: '',
  YEAR_NOT_VALID: '',
  ZIP_CODE_NOT_VALID: '',
  CALIFORNIA_NOTICE: '',
  TOP_LEARNER_LEADERBOARD_TITLE: '',
  TOP_LEARNER_LEADERBOARD_DESCR: '',
  EMPTY_TOP_LEARNER_LEADERBOARD_DESCR: '',
  YEAR_LEADERBOARD_TEXT_TAB1: 'YEAR{N}',
  OVERALL_LEADERBOARD_TEXT_TAB2: 'OVERALL',
  CREDITS_LABEL: '',
  TORTONA_TITLE: '',
  TORTONA_DESCRIPTION: '',
  STOP_VIDEO: '',
  TORTONA_CTA: '',
  PART_OF_RECORDING: '',
  LESSON_HARVARD_CTA: '',
  LESSON_HARVARD_TOOLTIP: '',
  //COLLECTIONS WAVE 2 LABELS
  FILTERS_INLINE_RESULTS: '{N} Results',
  FILTERS_INLINE_RESET_FILTERS: 'Reset filters',
  L1_HARVARD_BANNER_CTA: '',
  L1_HARVARD_BANNER_TITLE: '',
  L1_HARVARD_BANNER_DESCR: '',
  L1_HARVARD_BANNER_TOOLTIP: '',
  MODULES_TITLE: 'Modules',
  ENGLISH_SUBTITLE: 'English Subtitle',
  FRENCH_SUBTITLE: 'French Subtitle',
  SPANISH_SUBTITLE: 'Spanish Subtitle',
  GERMAN_SUBTITLE: 'German Subtitle',
  DUTCH_SUBTITLE: 'Dutch Subtitles',
  ITALIAN_SUBTITLE: 'Italian Subtitle',
  CHINESE_SUBTITLE: 'Chinese Subtitle',
  JAPANESE_SUBTITLE: 'Japanese Subtitle',
  PORTOGUESE_SUBTITLE: 'Portogues Subtitle',
  // COLLECTION PREVIEW
  COLLECTION_PREVIEW_TEXT: 'You are currently on collection preview mode',
  COLLECTION_PREVIEW_BACK_TO_BACKOFFICE: 'Return to backoffice',
  //EL360
  EL360_LABEL: "EL360",
  EL360_ACTIVE: 'Active',
  EL360_INCLUDED_IN_EL360_PROGRAM_LABEL: "Included in EL360 program.",
  //ECOMMERCE 1.6
  ECOMM16_LABEL: "PREMIUM ACCESS PLAN",
  ECOMM16_ACTIVE: "Active",
  //LEON 3217
  LEGAL_DISCLAIMER: "The content on the LEONARDO platform is part of a global content library. Due to this, the steps and details explained in our content act as example of the local recommended approaches. Please note that some elements may vary from country to country, for example, but not limited to, local regulations, stores or practices, doctors or opticians, customers or patients, and uniforms and attire worn. In addition, some content may not be available in local languages.However, it is still made available as we believe it provides valuable insights and information to all our users.",
  LEGAL_DISCLAIMER_SECTION_TITLE: "Legal Disclaimer",
  // BUILD YOUR CAREER
  BUILD_CAREER_TITLE: "Build your career",
  BUILD_CAREER_DESCRIPTION: "Wide range of programs tailored specifically for your needs in the practice, to grow professionally in each role and boost the business.",
  BUILD_CAREER_DESCRIPTION_MOBILE: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  BUILD_CAREER_BANNER_TOP_CAREER_PROGRAM: "Role specific program",
  BUILD_CAREER_BANNER_TOP_UPSKILL_PROGRAM: "Subject matter program",
  BUILD_CAREER_BANNER_TOP_MASTER_PROGRAM: "Management development program",
  SCROLL_DOWN_TO_DISCOVER: "Scroll down to discover more",
  CAREER_PROGRAMS_TITLE: "Role specific programs",
  CAREER_PROGRAMS_DESCRIPTION: "Get certified as a qualified industry professional selecting the best-fitting program for your role following our three-step journey:",
  CAREER_PROGRAMS_ESSENTIALS_TITLE: "Essentials",
  CAREER_PROGRAMS_ESSENTIALS_DESCRIPTION: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien. Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.",
  CAREER_PROGRAMS_ADVANCED_TITLE: "Advanced",
  CAREER_PROGRAMS_ADVANCED_DESCRIPTION: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien. Lorem ipsum dolor sit amet consectetur adipiscing elit.",
  CAREER_PROGRAMS_GURU_TITLE: "Guru",
  CAREER_PROGRAMS_GURU_DESCRIPTION: "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.",
  CAREER_PROGRAMS_L1_CARDS_TITLE: "Who are you?",
  UPSKILL_PROGRAMS_TITLE: "Subject matter programs",
  UPSKILL_PROGRAMS_DESCRIPTION: "Enrich your curriculum and acquire new skills selecting among our programs focused on the different areas of expertise.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  UPSKILL_PROGRAMS_DESCRIPTION_MOBILE: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  MDP_TITLE: "Management development program (MDP)",
  MDP_DESCRIPTION: "Grow your practice through a 6-month learning program combining face-to-face sessions with live virtual classes to sharpen your Practice Managers' business operations skills.",
  CHECK_OTHER_CAREER_PROGRAMS: "Not your current role? Check out this other role specific programs.",
  DEEP_DIVE_CAREER_PROGRAMS: "Do you want to dive deeper into your role specifics?",
  VERTICAL_SPECIALIZATION_UPSKILL_PROGRAMS: "Are you looking for a more vertical specialization? Check out our",
  CURRICULUM_LABEL: "The curriculum",
  BRANDS_AND_PRODUCTS: "Brands and products",
  VISION_CARE: "Vision care",
  VISION_CARE_TITLE: "Vision Care",
  CUSTOMER_JOURNEY: "Customer journey",
  PRACTICE_OPERATIONS: "Practice operations",
  KPI_MANAGEMENT: "KPI management",
  PROGRAM_DURATION: "Program duration",
  EXPECTED_COMPLETION_TIME: "Expected completion time",
  HOURS_LABEL: "hours",
  MONTHS_LABEL: "months",
  PROGRAM_IN_PREMIUM_ACCESS_PLAN: "Please note: this program is also included in the Premium Access Plan.",
  ROLE_SPECIFIC_PROGRAM_LABEL: "Role specific program",
  ROLE_SPECIFIC_PROGRAM_CONGRATULATIONS_DOWNLOAD_CERTIFICATE: "Congratulations! You earned your {role} certification",
  ROLE_SPECIFIC_PROGRAM_TITLE: "your certificate",
  UPSKILL_PROGRAMS_SECTION_TITLE_BRAND_PROGRAM: 'Brand programs',
  UPSKILL_PROGRAMS_SECTION_DESCRIPTION_BRAND_PROGRAMS: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.',
  UPSKILL_PROGRAMS_SECTION_TITLE_VISION_CARE: 'Vision care',
  UPSKILL_PROGRAMS_SECTION_DESCRIPTION_VISION_CARE: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.',
  UPSKILL_PROGRAMS_SECTION_TITLE_SALES_BUSINESS: 'Sales and business',
  UPSKILL_PROGRAMS_SECTION_DESCRIPTION_SALES_BUSINESS: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.',
  UPSKILL_PROGRAMS_SECTION_TITLE_PEOPLE_DEVELOPMENT: 'People development',
  UPSKILL_PROGRAMS_SECTION_DESCRIPTION_PEOPLE_DEVELOPMENT: 'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.',
  UPSKILL_PROGRAMS_LINKS_DESCRIPTION: 'Do you want to dive deeper into your role specifics?',
  UPSKILL_PROGRAMS_BTN_TOP_TITLE: 'SUBJECT MATTER PROGRAM',
  UPSKILL_PROGRAMS_BTN_TOP_DESCRIPTION:
  'Enrich your curriculum and acquire new skills selecting among our programs focused on the different areas of expertise.' ,
  BYC_L1_BANNER_PROGRAMS_TITLE: 'Programs',
  BYC_L1_BANNER_PROGRAMS_DESCR: 'Discover a selection of educational training content to help you get a head start on your professional journey',
  //EVO 2.2B
  RECORDING_VC: 'Recording',
  LIVE_VC: 'Live',
  SEATS_VC_AVAILABLE: "seats left",
  PROFILE_PERSONIFICATION_TITLE: 'View site as a different user',
  PROFILE_PERSONIFICATION_DESCRIPTION: 'Select a user from the list and browse the Leonardo platform from their view.',
  PROFILE_PERSONIFICATION_TABLE_MACROUSER: 'Macro User',
  PROFILE_PERSONIFICATION_TABLE_MACROUSER_AZ: 'Macro User A-Z',
  PROFILE_PERSONIFICATION_TABLE_MACROUSER_ZA: 'Macro User Z-A',
  PROFILE_PERSONIFICATION_TABLE_BRAND: 'Brand',
  PROFILE_PERSONIFICATION_TABLE_AREA: 'Area',
  PROFILE_PERSONIFICATION_TABLE_COUNTRY: 'Country',
  PROFILE_PERSONIFICATION_TABLE_ROLE: 'Role',
  PROFILE_PERSONIFICATION_TABLE_ACTION: 'Select',
  PROFILE_PERSONIFICATION_TABLE_ACTION_EXIT: 'Exit',
  PROFILE_PERSONIFICATION_BANNER_SIMULATION_ON: 'You have hidden the banner. In order to exit from this user view go to the Settings page.',
  PROFILE_PERSONIFICATION_BANNER_SIMULATION_OFF: 'You are now interacting from your own profile.',
  PROFILE_PERSONIFICATION_LABEL_FIXED_BANNER_SIMULATON_ON: 'You are browsing as a',
  PROFILE_PERSONIFICATION_LABEL_FIXED_BANNER_SIMULATON_CTA_HIDE: 'Hide',
  PROFILE_PERSONIFICATION_NO_USERS: 'Sorry, you don’t have any user view available at the moment.',
  PROFILE_PERSONIFICATION_IN_USE_BADGE: 'In use',
  PROFILE_PERSONIFICATION_LABEL_WARNING_ON_START_SIMULATION_ALREADY_ON: 'You have already started a user simulation from another device. Please exit the other simulation in order to start a new one.',
  PROFILE_PERSONIFICATION_LABEL_TITLE_MODAL: 'Filters and sorting',
  PROFILE_PERSONIFICATION_LABEL_CHECKBOX_MY_TEAM_INLINE: 'Team view',
  // PROFILE_PERSONIFICATION_LABEL_CHECKBOX_MY_TEAM_MODAL: 'Manage view', // not used anymore
  TABLEV2_PAGINATION: 'of',
  //LEON 2175
  DOWNLOAD_BROCHURE: 'Download brochure',
  COURSE_SAVING_PROGRESS: 'Saving progress',
  //CERTIFICATES
  CERT_MODAL_TITLE: "Download certificate",
  //CERT_CERTIFICATE_CHECKBOX: "Always use this name", OUT OF SCOPE
  CERT_CHECK_NAME_ON_CERTIFICATE: "Check the name that will appear on your certificate.",
  CERT_NAME_ON_CERTIFICATE_LABEL: "Name on the certificate",
  CERT_NAME_ON_CERTIFICATE_LABEL_PLACEHOLDER: "Placeholder text",
  CERT_LICENSE_NUMBER: "License number",
  CERT_LICENSE_NUMBER_PLACEHOLDER: "Placeholder text",
  CERT_ADDITIONAL_INFO_ONE: "Info xyk 1",
  CERT_ADDITIONAL_INFO_ONE_PLACEHOLDER: "Placeholder text",
  CERT_ADDITIONAL_INFO_TWO: "Info xyk 2",
  CERT_ADDITIONAL_INFO_TWO_PLACEHOLDER: "Placeholder text",
  //RECOMMENDEDINTODO
  RECOMMENDED_IN_TODO_TITLE: "Recommended",
  TODO_COMPLETED_ALL_ACTIVITIES: "You have completed all of your assigned activities. Keep learning and check your recommended content!",
  TODO_ALL_DONE_FOR_THE_DAY: "All done for the day! You have completed all of your assigned activities.",
  //LEON 2795
  BLOCKED_PROGRAM_INFO: "Click to find out how to unlock this program.",
  BLOCKED_PROGRAM_INFO_COURSE_NAME: "Complete {course} before starting this program",  
  BLOCKED_PROGRAM_INFO_CARD: "This program is locked",
  VIEW_DETAILS_EP:"View details",
  EMPTY_VC_COMINGSOON: "COMING SOON",
  EMPTY_VC_DESCRIPTION: "Please check back later to find out the details of this live session.",
  //BYC OFFICE PAGE
  BYC_OFFICE_BANNER_TITLE: "Build your career",
  BYC_OFFICE_BANNER_DESCRIPTION: "Ready to work on your personal and professional development? Grow in your role by developing new skills, exploring the Company's shared values, and enhancing your knowledge of EssilorLuxottica's brands and products with our dedicated programs.",
  BYC_OFFICE_BANNER_DESCRIPTION_MOBILE: "Welcome to Build your Career, the ultimate section that offers structured programs for your personal and professional development. Grow in your role and develop new skills by engaging in EssilorLuxottica’s Competencies Hub and Specialty Programs.",
  BYC_OFFICE_BANNER_BUTTON_EDUCATIONAL_CATALOG_BYC: 'EssilorLuxottica competencies',
  BYC_OFFICE_BANNER_BUTTON_BUILD_YOUR_CAREER_UP_OFFICE: 'Specialty programs',
  BYC_OFFICE_COMPETENCIES_TITLE: "Essilorluxottica competencies",
  BYC_OFFICE_COMPETENCIES_DESCRIPTION: "Welcome to EssilorLuxottica's Competency Hub, where we focus on the 'how' of delivering and acting our key behaviors. Here, you can drive your professional growth with EssilorLuxottica's 15 Competencies, tailor your learning experience based on your developmental needs, and access a wealth of learning resources designed to enhance your skills.",
  BYC_OFFICE_COMPETENCIES_ONE_DESCRIPTION: "As an Individual Contributor, I work in collaboration with others to accomplish my tasks and provide support to my team. Discover all learning paths to help me develop my full competency set.​",
  BYC_OFFICE_COMPETENCIES_TWO_DESCRIPTION: "As a Manager, I lead my team to ensure that departmental and individual performance goals are met. Discover all learning paths to help me develop my full competency set.​",
  BYC_OFFICE_COMPETENCIES_THREE_DESCRIPTION: "As a Manager of Managers, I lead and oversee the work of managers and others, and co-ordinate multiple teams to drive performance and achieve the Group's business objectives, ensuring timely project delivery. Discover all learning paths to help me develop my full competency set.​",
  BYC_OFFICE_COMPETENCIES_CARDS_TITLE: "Explore the competencies around your role",
  BYC_OFFICE_SPECIALTY_TITLE: "Specialty programs",
  BYC_OFFICE_SPECIALTY_DESCRIPTION: "Enrich your curriculum and acquire new skills by selecting from our programs focused on various areas of expertise.",
  BYC_OFFICE_SPECIALTY_ONBOARDING_BUTTON: 'Onboarding',
  BYC_OFFICE_SPECIALTY_DISCOVER_MORE: 'Discover programs',
  //BYC OFFICE SPECIALTY PROGRAMS PAGE
  BYC_OFFICE_SPECIALTY_PROGRAMS_BANNER_TITLE: "Specialty programs",
  BYC_OFFICE_SPECIALTY_PROGRAMS_BANNER_DESCRIPTION: "Ready to become an industry expert? Immerse yourself in our brands, discover the fundamentals of vision care, and master the art of business.",
  BYC_OFFICE_SPECIALTY_PROGRAMS_ONBOARDING_BUTTON: "Onboarding",
  BYC_OFFICE_SPECIALTY_PROGRAMS_ONBOARDING_TITLE: "Onboarding",
  BYC_OFFICE_SPECIALTY_PROGRAMS_ONBOARDING_DESCRIPTION: "Ready to master the customer journey and improve your selling skills? Discover how to embrace digitalizations in business, enhance the day-to-day operations in-store, and provide an unbeatable service for every patient.",
  //PODCAST
  PODCAST_HEADER: "Podcast",
  PODCAST_DESCRIPTION: "A selection of content of different topics, featuring podcasts for immersive listening experiences and vocasts that can also be watched.",
  PODCAST_CTA: "Discover podcasts",
  CMS_NEW_PAGE_HOT_AIR_BALOON_DESCRIPTION: "We are sorry, this content is not available.",
  
  FILTER_CERTIFICATE_AVAILABLE: "Certificate available",
  //LEON 3577
  SESSION_EXPIRED_TITLE: "SESSION EXPIRED",
  SESSION_EXPIRED_DESCRIPTION : "Your session has expired. For security reasons, the session duration is limited to a maximum of 4 hours of inactivity.</br></br>To continue using Leonardo, pleas log in again.",
// MANAGER ASSIGNMENT PDP
CTA_ASSIGN_TO_TEAM_PDP: "Assign this to your team",
//MANAGER ASSIGNMENT LOADING
MANAGER_ASSIGNMENT_TITLE_PAGE: "ASSIGN TO YOUR TEAM",
MANAGER_ASSIGNMENT_PAGE_DESCRIPTION: 'Lesson: "{lesson}" - "{l1}" ({duration})',
MODALS_TUTORIAL_ASSIGNMENT_TITLE: "how does the assignment work?",
MANAGER_ASSIGNMENT_LOADING_PAGE_SUBTITLE: "HOW DOES THE ASSIGNMENT WORK?",
MANAGER_ASSIGNMENT_BOX_1: "You can select which team members to assign this lesson to",
MANAGER_ASSIGNMENT_BOX_2: "You can also select users who have already completed the lesson for re-trainment if you can think they shouls re-do it",
MANAGER_ASSIGNMENT_BOX_3: "Assignees will receive a notification and will be able to see the content in their ToDo page",
MANAGER_ASSIGNMENT_LOADING_TITLE: "We are loading your team catalog",
MANAGER_ASSIGNMENT_PROCEED_TO_ASSIGNMENT_CTA: "PROCEED TO ASSIGNMENT",
MANAGER_ASSIGNMENT_LOADING_ERROR: "We are sorry, there was an error checking the educational catalog and activity status of your team members.Please try again or come back later.",
MANAGER_ASSIGNMENT_PROCEED_TO_ASSIGNMENT_ERROR_CTA: "TRY AGAIN",
// MANAGER ASSIGNMENT TABLE
MANAGER_ASSIGNMENT_MORE_INFO: "More Info",
MANAGER_ASSIGNMENT_PAGE_SUPPORT: "If someone from your team is missing, please contact our HR offices in Milan, Italy by writing an email to ",
MANAGER_ASSIGNMENT_PAGE_SUPPORT_MAIL: "loremipsum@email.com.",
MANAGER_ASSIGNMENT_SEARCH_PLACEHOLDER: "Search or select team member",
MANAGER_ASSIGNMENT_TABLE_NAME: "Name surname",
MANAGER_ASSIGNMENT_TABLE_JOB: "Job title",
MANAGER_ASSIGNMENT_TABLE_LESSON_STATUS: "Lesson status",
MANAGER_ASSIGNMENT_TABLE_ASSIGN_ALL: "Assign to all",
MANAGER_ASSIGNMENT_TABLE_REASSIGN_ALL: "Reassign to all",
MANAGER_ASSIGNMENT_STEP_1_OF_2: "STEP 1 OF 2",
MANAGER_ASSIGNMENT_USERS_SELECTED_COUNT: "USERS SELECTED: ",
MANAGER_ASSIGNMENT_CONTINUE_TO_RECAP: "CONTINUE TO RECAP",
MANAGER_ASSIGNMENT_ERROR_SELECTION: "You need to select at least one team member to proceed",
MANAGER_ASSIGNMENT_SORT_AZ: "(A - Z)",
MANAGER_ASSIGNMENT_SORT_ZA: "(Z - A)",
//ASSIGNMENT RECAP
MANAGER_ASSIGNMENT_RECAP_TITLE: "ASSIGNMENT RECAP",
MANAGER_ASSIGNMENT_RECAP_PAGE_DESCRIPTION: "Review your selection before confirming assignments and re-assignments.",
MANAGER_ASSIGNMENT_RECAP_PAGE_DESCRIPTION_LESSON: "Lesson: {lesson} ({duration})",
MANAGER_ASSIGNMENT_RECAP_ASSIGNED_TO: "You assigned to:",
MANAGER_ASSIGNMENT_RECAP_REASSIGNED_TO: "You re-assigned to:",
MANAGER_ASSIGNMENT_RECAP_CTA_CONFIRM: "Confirm Assignment",
MANAGER_ASSIGNMENT_STEP_2_OF_2: "STEP 2 OF 2",
//ASSIGNMENT CONFIRMATION
MANAGER_ASSIGNMENT_CONFIRM_PAGE_TITLE: "ASSIGNMENT CONFIRMATION",
MANAGER_ASSIGNMENT_CONFIRM_PAGE_DESCRIPTION: "Lesson: {lesson} ",
MANAGER_ASSIGNMENT_CONFIRM_PAGE_DESCRIPTION_DURATION: "{duration}",
MANAGER_ASSIGNMENT_CONFIRM_PAGE_MAIN_TEXT: "You will receive a notification when the assignment process is completed, and the selected team members will receive a notification too.",
MANAGER_ASSIGNMENT_CONFIRM_PAGE_CTA: "GO TO HOMEPAGE",
// MANAGER ASSIGNMENT - MODALS AND CAROUSEL
MODALS_QUIT_ASSIGNMENT_TITLE: "you are quitting the assignment process",
MODALS_QUIT_ASSIGNMENT_DESCRIPTION: "If you leave now, your progress will be lost.",
QUIT: "Quit",
KEEP_ASSIGNING: "Keep assigning",
ASSIGNED_CONTENT_TITLE: "ASSIGNED CONTENT",
ASSIGNED_CONTENT_DESCRIPTION: "Here you can find the content that your manager assigned to you to complete and some suggestions by Leonardo.",
RETRAIN_CONTENT_TITLE: "RE-TRAIN CONTENT",
RETRAIN_CONTENT_DESCRIPTION: "This section contains the mandatory content your manager has re-assigned to you. Your previous completion has been overridden.",

MANAGER_ASSIGNMENT_RECAP_VIEW_ALL: "View All",
MANAGER_ASSIGNMENT_RECAP_VIEW_LESS: "View Less",
MANAGER_ASSIGNMENT_TABLE_ALREADY_MANDATORY: "Content already mandatory/overdue or not visible to the user",
MANAGER_ASSIGNMENT_TABLE_ALREADY_ASSIGNED: "Content already assigned on {date}",
MANAGER_ASSIGNMENT_TABLE_SELECT_CHECKBOX: "Select",
MANAGER_ASSIGNMENT_TABLE_REASSIGN_CHECKBOX: "Re-assign",
MANAGER_ASSIGNMENT_TABLE_SELECTED_CHECKBOX: "Selected",
MANAGER_ASSIGNMENT_TABLE_REASSIGNED_CHECKBOX: "Re-assigned",
MANAGER_ASSIGNMENT_TABLE_DESELECT_ALL: "Deselect All",
MANAGER_ASSIGNMENT_SEARCH_PLACEHOLDER: "Search or Select Team member",
MANAGER_ASSIGNMENT_RESET_FILTERS: "Reset filters",
MANAGER_ASSIGNMENT_FILTER_TOTAL: "{p} of {total} result",
MANAGER_ASSIGNMENT_RESEARCH_NO_RESULT: "Your search returned no results, please double check your spelling.",
};

