import * as actionTypes from './actionTypes';
const axios = require('axios');
import { setCookie, getCookie, removeCookie } from '../../utility/cookie';
import * as urlList from '../../config';
import { showError } from './utilsActions';
import { getHeaders, handleSeedRandomHeroBanner } from '../../utility/Api';
import {
  API_ADMIN_STATUS_CODE,
  COOKIE,
  HTTP_REDIRECT_STATUS,
  LOGIN_ERROR,
  queryParams,
} from '../../utility/const';
import { logoutFirebase, signInFirebase } from './firebaseActions';
import { getLanguages } from '.';
import { PUBLIC_URLS, styledLogUtagView } from '@components/link-utils';
import { LEADERBOARD_PRESENCE, NOTIFICATION_CHANNELS } from '@model/User';
import { promiseMock } from '@utility/ecommerceUtility';
import {
  requestUpdateUser,
  updateUserFailureHandler,
  updateUserSuccessHandler,
} from './userActions';
import { isInStandaloneMode } from '@utility/pwaUtility';
import history from '../../history';
import {
  isPersonificationOn,
  stopPersonification,
  useStopPersonificate
} from "../../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic";
import { triggerErrorEvent, triggerTrackEvent } from '@utility/analytics-utils';
import { ErrorTypes } from '@model/TrackingClass';

export const login = (username, password, cidParam, showLoginModalLoader = false) => {
  return dispatch => {
    dispatch(requestLogin(false, showLoginModalLoader));
    
    let url = urlList.OAUTH_AUTHENTICATION;
    let body = {
      username,
      password,
    };
    let token = getCookie(COOKIE.REFRESH_TOKEN);
    if (isInStandaloneMode() && token) {
      url = urlList.OAUTH_AUTHENTICATION_REFRESH;
      body = {
        RefreshToken: getCookie('refreshToken'),
      };
    }
    axios
      .post(url, body)
      .then(response => {
        const token = response.data.refreshToken;
        if (token) {
          setCookieWithRefreshToken(token);
        }

        const responseCode = response.data.responseCode;

        if(!responseCode) {
          let utagEventData = {
            Events_UserLogin: '1'
          }
          triggerTrackEvent(utagEventData);
          styledLogUtagView("Login event", utagEventData)
        }

        if (responseCode === 'essilor') {
          if (!response.data.redirectUrl) {
            console.error('Url to B2C is not present');
            dispatch(failureLogin(null));
            dispatch(showError(err));
          }

          if (cidParam) {
            window.location.href = '/' + cidParam;
            return;
          }
          window.location.href = response.data.redirectUrl;
          return;
        }

        if (responseCode === 'external' || responseCode === 'myl') {
          if (!response.data.redirectUrl) {
            console.error('Url to B2C is not present');
            dispatch(failureLogin(null));
            dispatch(showError(err));
          }

          let externalUsername = username;
          if (responseCode === 'myl' && response.data.mylUsername) {
            externalUsername += response.data.mylUsername;
          }

          const b2cParam = {
            grant_type: response.data.grant_type,
            scope: response.data.scope,
            client_id: response.data.client_id,
            response_type: response.data.response_type,
          };

          dispatch(
            getAuthTokenB2C(
              externalUsername,
              password,
              response.data.redirectUrl,
              b2cParam,
              username,
              cidParam
            )
          );
          return;
        }
        if (cidParam) {
          window.location.href = '/' + cidParam;
        }
        dispatch(handleCookies(response, username, password));
        handleSeedRandomHeroBanner();
      })
      .catch(err => {
        if (err.response?.status === HTTP_REDIRECT_STATUS && err.response.data.redirectUrl) {
          //redirect to the url set by bff
          window.location.href = err.response.data.redirectUrl;
        } else if (err.response?.status === 401) {
          handleExpiredPassword(dispatch, err);
          let utagErrorData = {
            Error_Source: ErrorTypes.user,
            Error_Code: 'Invalid credentials',
            Error_Message: 'password expired'
          }
          triggerErrorEvent(utagErrorData)
          styledLogUtagView(utagErrorData?.Error_Code, utagErrorData)
        } else {
          dispatch(failureLogin(null));
          dispatch(showError(err));
        }
      });
  };
};

const requestLogin = (isLoggingMylToken = false, showLoginModalLoader = false) => {
  return {
    type: actionTypes.LOGIN_START,
    isLoggingMylToken,
    showLoginModalLoader,
  };
};

const successLogin = cookies => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    cookies,
  };
};

const failureLogin = error => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error,
  };
};

export const togglePassExpiredPopup = show => {
  return {
    type: actionTypes.TOGGLE_PASS_EXPIRED_POPUP,
    show,
  };
};

export const toggleShowMYLKOPopup = show => {
  return {
    type: actionTypes.TOGGLE_MYL_KO_POPUP,
    show,
  };
};

const handleCookies = (response, username, password) => {
  return dispatch => {
    let cookies = response.data;
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // setCookie(COOKIE.MOODLE_SESSION, cookies.moodleSession, tomorrow.getTime(), "luxottica.com");

    let moodleSessionExpiration = tomorrow.getTime();
    if (location.hostname === 'localhost' || location.hostname.includes('ngrok')) {
      setCookie(COOKIE.MOODLE_SESSION, cookies.moodleSession, moodleSessionExpiration);

      //store firebase token for notifications
      if (response && response.data && response.data.firebaseToken) {
        setCookie(
          COOKIE.FIREBASE_TOKEN,
          response.data.firebaseToken,
          new Date().getTime() + 1000 * 60 * 59
        ); //expiration = now + 59min
      } else {
        console.error('Firebase token not found in the login response');
      }

      //store user token for notification panel requests
      if (response && response.data && response.data.userToken) {
        setCookie(COOKIE.USER_TOKEN, response.data.userToken, moodleSessionExpiration);
      } else {
        console.error('User token not found in the login response');
      }

      //for now all users are enabled to ecommerce
      if (response?.data?.ecommerceStatus) {
        setCookie(COOKIE.ECOMMERCE, response.data.ecommerceStatus, moodleSessionExpiration);
      }
    }

    dispatch(successLogin(cookies));
  };
};

export const logout = (removeCredentialsCookie = false) => {
  return dispatch => {
    let url = urlList.LOGOUT_URL;

    axios({
      url,
      method: 'POST',
      headers: getHeaders(),
    })
      .then(json => {
        localStorage.removeItem('authentication');

        if (location.hostname === 'localhost' || location.hostname.includes('ngrok')) {
          removeCookie(COOKIE.MOODLE_SESSION);
        } else {
          removeCookie(COOKIE.MOODLE_SESSION, 'luxottica.com');
          removeCookie(COOKIE.MOODLE_SESSION, 'essilorluxottica.com');
        }
        removeCookie(COOKIE.USER_TOKEN);
        removeCookie(COOKIE.FIREBASE_TOKEN);
        removeCookie(COOKIE.ECOMMERCE);
        removeCookie(COOKIE.USER_DETAILS, '.essilorluxottica.com');
        removeCookie(COOKIE.USER_DETAILS);
        removeCookie(COOKIE.AUTORENEWAL_ACTIVE);
        removeCookie(COOKIE.PLAN_PURCHASED);
        removeCookie(COOKIE.REFRESH_TOKEN);
        removeCookie(COOKIE.COLLECTION_PREVIEW);
        //remove CmsPreview cookie if present
        // let cmsPreview = getCookie(COOKIE.CMS_PREVIEW);
        // if(cmsPreview) {
        //   dispatch(actions.setShowCMSPreviewBanner(false))
        //   removeCookie(COOKIE.CMS_PREVIEW)
        // }
        //remove mylToken
        removeCookie(COOKIE.MYL_TOKEN, '.essilorluxottica.com');
        removeCookie(COOKIE.MYL_TOKEN);
        sessionStorage.clear();
        localStorage.removeItem('userStates');
        localStorage.removeItem('userToSend');
        localStorage.removeItem('usersToAssign');
        localStorage.removeItem('usersToAssignFinal');
        localStorage.removeItem('usersToReAssignFinal');
        dispatch(logoutFirebase());
        dispatch(logoutRedux());

        if (history) {
          if (getCookie(COOKIE.SESSION_FINISHED)) {
            removeCookie(COOKIE.SESSION_FINISHED);
            return history.push(PUBLIC_URLS.HOMEPAGE.URL);
          }
          return history.push(PUBLIC_URLS.LOGIN.URL);
        }
        

      })
      .catch(err => {
        dispatch(showError(err));
      });
  };
};

export const logoutRedux = () => {
  return { type: actionTypes.LOGOUT };
};

export const setGACheck = value => {
  return {
    type: actionTypes.SET_GA_CHECK,
    value,
  };
};

export const checkAuthenticationState = () => {
  return (dispatch, getState) => {
    let token = getCookie(COOKIE.USER_DETAILS);
    if (token != null && token != '') {
      let cookies = { [COOKIE.USER_DETAILS]: token };
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        cookies,
      });
    }
  };
};

export const checkAuthenticationStateWithToken = token => {
  return (dispatch, getState) => {
    if (token != null && token != '') {
      let cookies = { [COOKIE.USER_TOKEN]: token };
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        cookies,
      });
    }
  };
};

export const checkTokenTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
};

export const cleanError = () => {
  return {
    type: actionTypes.CLEAN_ERROR_LOGIN,
  };
};

export const getFirebaseToken = () => {
  return dispatch => {
    console.log('Refresh firebase token');

    const url = urlList.GET_FIREBASE_TOKEN;
    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        let cookies = response.data;

        //store firebase token for notifications
        if (response && response.data && response.data.firebaseToken) {
          setCookie(
            COOKIE.FIREBASE_TOKEN,
            response.data.firebaseToken,
            new Date().getTime() + 1000 * 60 * 59
          ); //expiration = now + 59min
        } else {
          console.error('Firebase token not found in the refresh token response');
        }

        dispatch(success(cookies));

        //sign in on firebase
        dispatch(signInFirebase());
      })
      .catch(err => {
        if (err.response.status === 401 || err.response.status === 503)
          dispatch(failure(err.response.data.message));
        else {
          dispatch(failure(null));
          dispatch(showError(err));
        }
      });
  };

  function success(cookies) {
    return {
      type: actionTypes.FIREBASE_TOKEN_SUCCESS,
      cookies,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.FIREBASE_TOKEN_FAILURE,
      error,
    };
  }
};

export const getAuthTokenB2C = (
  username,
  password,
  b2cUrl,
  b2cBodyParam,
  usernameCIAM,
  cidParam
) => {
  return dispatch => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', b2cBodyParam.grant_type);
    params.append('scope', b2cBodyParam.scope);
    params.append('client_id', b2cBodyParam.client_id);
    params.append('response_type', b2cBodyParam.response_type);

    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };

    axios
      .post(b2cUrl, params, config)
      .then(response => {
        const authToken = response.data?.access_token;

        if (!authToken) {
          console.error('Error getting authToken by B2C');
          dispatch(failureLogin(null));
          dispatch(showError(err));
        }

        dispatch(loginCIAM(usernameCIAM, authToken, username, password, cidParam));
      })
      .catch(err => {
        if (err.response?.status === HTTP_REDIRECT_STATUS && err.response.data.redirectUrl) {
          //redirect to the url set by bff
          window.location.href = err.response.data.redirectUrl;
        } else if (err.response?.status === 401) {
          handleExpiredPassword(dispatch, err);
        } else if (err.response?.status === 400) dispatch(failureLogin('ERROR_LOGIN'));
        else {
          dispatch(failureLogin(null));
          dispatch(showError(err));
        }
      });
  };
};

export const loginCIAM = (username, authToken, usernameOriginal, password, cidParam) => {
  return dispatch => {
    const config = { headers: { authToken: authToken } };
    let url = urlList.CIAM_AUTHENTICATION;
    if (username) {
      //if the username is not present --> it should be taken by the userToken passed in the header
      url += '?username=' + username;
    }
    if (isInStandaloneMode() && getCookie('RefreshToken')) {
      url = urlList.OAUTH_AUTHENTICATION_REFRESH;
      body = {
        RefreshToken: getCookie('RefreshToken'),
      };
      axios
        .post(url, body)
        .then(response => {
          dispatch(handleCookies(response, usernameOriginal, password));
          let utagEventData = {
            Events_UserLogin: '1'
          }

          triggerTrackEvent(utagEventData);
          styledLogUtagView("Login event", utagEventData)
        })
        .catch(err => {
          if (err.response?.status === 401) dispatch(failureLogin(err.response.data.message));
          else {
            dispatch(failureLogin(null));
            dispatch(showError(err));
          }
        });
      return;
    }

    axios
      .post(url, null, config)
      .then(response => {
        if (cidParam) {
          window.location.href = '/' + cidParam;
        }
        dispatch(handleCookies(response, usernameOriginal, password));
        let utagEventData = {
          Events_UserLogin: '1'
        }
        triggerTrackEvent(utagEventData);
        styledLogUtagView("Login event", utagEventData)

      })
      .catch(err => {
        if (err.response?.status === HTTP_REDIRECT_STATUS && err.response.data.redirectUrl) {
          //redirect to the url set by bff
          window.location.href = err.response.data.redirectUrl;
        } else if (err.response?.status === 401) {
          handleExpiredPassword(dispatch, err);
          let utagErrorData = {
            Error_Source: ErrorTypes.user,
            Error_Code: 'Invalid credentials',
            Error_Message: 'password expired'
          }
          triggerErrorEvent(utagErrorData)
          styledLogUtagView(utagErrorData?.Error_Code, utagErrorData)
        } else {
          dispatch(failureLogin(null));
          dispatch(showError(err));
        }
      });
  };
};

const handleExpiredPassword = (dispatch, err) => {
  if (err?.response?.data?.message === LOGIN_ERROR.PASS_EXPIRED) {
    if (err.response.data.resetPasswordToken) {
      dispatch(failureLogin(''));
      history.push(
        PUBLIC_URLS.EXPIRED_PASSWORD.URL + '?token=' + err.response.data.resetPasswordToken
      );
    } else {
      console.error('resetPasswordToken field not present on response');
      dispatch(failureLogin(err.response.data.message));
      dispatch(showError(''));
    }
  } else {
    dispatch(failureLogin(err?.response?.data?.message));
  }
};

export const loginMylToken = mylToken => {
  return dispatch => {
    dispatch(requestLogin(true));
    dispatch(loginCIAM('', mylToken));
  };
};

export const register = userRegister => {
  return dispatch => {
    dispatch(request());

    axios
      .post(urlList.REGISTER_USER, userRegister)
      .then(response => {
        if (!response.data) {
          dispatch(failure('error'));
          dispatch(showError(''));
        }

        let utagEventData = {
          Events_UserAccountNew: '1',
        }

        switch (response.data.statusCode) {
          case API_ADMIN_STATUS_CODE.OK:
            dispatch(success(userRegister.userType));
            triggerTrackEvent(utagEventData)
            styledLogUtagView("Register event")
            break;
          case API_ADMIN_STATUS_CODE.EMAIL_ALREADY_EXISTS:
            //there is already an user with the inserted email
            dispatch(failure(API_ADMIN_STATUS_CODE.EMAIL_ALREADY_EXISTS));
            break;
          case API_ADMIN_STATUS_CODE.PENDING_REQUEST:
            //The requested subuser is used by a pending request, needs to be accepted by main user
            dispatch(failure(API_ADMIN_STATUS_CODE.PENDING_REQUEST));
            break;
          case API_ADMIN_STATUS_CODE.PASS_WRONG_FORMAT:
            //error not handled --> generic error
            dispatch(failure(API_ADMIN_STATUS_CODE.PASS_WRONG_FORMAT));
            dispatch(showError(''));
            break;
          case API_ADMIN_STATUS_CODE.GENERIC_ERROR:
          default:
            //error not handled --> generic error
            dispatch(failure(API_ADMIN_STATUS_CODE.GENERIC_ERROR));
            dispatch(showError(''));
            break;
        }
      })
      .catch(err => {
        console.error('Register error', err);
        dispatch(failure(API_ADMIN_STATUS_CODE.GENERIC_ERROR));
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.REGISTER_START,
    };
  }
  function success(userType) {
    return {
      type: actionTypes.REGISTER_SUCCESS,
      userType,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.REGISTER_FAILURE,
      error,
    };
  }
};

export const getCountries = (lang = 'en') => {
  return dispatch => {
    dispatch(request());

    const url = urlList.GET_COUNTRY_URL + '/' + lang;
    axios
      .get(url)
      .then(response => {
        if (!response.data) {
          dispatch(failure('error'));
          dispatch(showError(''));
        }

        dispatch(success(response.data));
      })
      .catch(err => {
        console.error('Get countries error', err);
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_COUNTRIES_START,
    };
  }
  function success(countries) {
    return {
      type: actionTypes.GET_COUNTRIES_SUCCESS,
      countries,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.GET_COUNTRIES_FAILURE,
      error,
    };
  }
};

export const getStatesOfResidence = (lang = 'en') => {
  return dispatch => {
    dispatch(request());

    const url = urlList.GET_STATE_OF_RESIDENCE_URL + '/' + lang;
    axios
      .get(url)
      .then(response => {
        if (!response.data) {
          dispatch(failure('error'));
          dispatch(showError(''));
        }

        dispatch(success(response.data));
      })
      .catch(err => {
        console.error('Get state of residence error', err);
        dispatch(failure());
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.GET_STATE_OF_RESIDENCE_START,
    };
  }
  function success(statesOfResidence) {
    return {
      type: actionTypes.GET_STATE_OF_RESIDENCE_SUCCESS,
      statesOfResidence,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.GET_STATE_OF_RESIDENCE_FAILURE,
      error,
    };
  }
};

export const resetRegisterResponse = () => {
  return {
    type: actionTypes.REGISTER_RESET,
  };
};

export const activateUser = token => {
  return dispatch => {
    dispatch(request());

    const url = urlList.ACTIVATE_USER_URL + '?token=' + token;

    axios
      .get(url)
      .then(response => {
        switch (response.data.statusCode) {
          case API_ADMIN_STATUS_CODE.OK:
            dispatch(success());
            break;
          case API_ADMIN_STATUS_CODE.EXPIRED_TOKEN:
            dispatch(failure(API_ADMIN_STATUS_CODE.EXPIRED_TOKEN));
            break;
          default:
            //error not handled --> generic error
            dispatch(failure(API_ADMIN_STATUS_CODE.GENERIC_ERROR));
            dispatch(showError(''));
            break;
        }
      })
      .catch(err => {
        console.error('activateUser error', err);
        dispatch(failure(API_ADMIN_STATUS_CODE.GENERIC_ERROR));
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.ACTIVATE_USER_REQUEST,
    };
  }
  function success() {
    return {
      type: actionTypes.ACTIVATE_USER_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.ACTIVATE_USER_FAILURE,
      error,
    };
  }
};

export const sendChangePswEmail = (username, language, userType, history) => {
  return dispatch => {
    dispatch(request());

    const url =
      urlList.SEND_CHANGE_PSW_EMAIL_URL +
      '?lang=' +
      language +
      //+ '&userType=' + userType
      '&username=' +
      username;

    axios
      .get(url)
      .then(response => {
        //a prescindere se la chiamata va in ok o ko --> mostra messaggio di successo
        console.log('USER', userType);
        dispatch(success());
        //go to success page
        if (history) {
          history.push('/forgot-password/success');
        }
      })
      .catch(err => {
        dispatch(failure(API_ADMIN_STATUS_CODE.GENERIC_ERROR));
        //  dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.CHANGE_PSW_EMAIL_REQUEST,
    };
  }
  function success() {
    return {
      type: actionTypes.CHANGE_PSW_EMAIL_SUCCESS,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.CHANGE_PSW_EMAIL_FAILURE,
      error,
    };
  }
};

export const resetPasswordPublic = (newPassword, token, history, t) => {
  return dispatch => {
    dispatch(request());

    const url = urlList.RESET_PSW_PUBLIC_URL + '?token=' + token;

    axios
      .post(url, { newPassword })
      .then(response => {
        switch (response.data.statusCode) {
          case API_ADMIN_STATUS_CODE.OK:
            dispatch(success(response.data.userType));
            //go to success page
            if (history) {
              history.push('/password-reset/success');
            }
            break;
          case API_ADMIN_STATUS_CODE.EXPIRED_TOKEN:
            dispatch(failure(API_ADMIN_STATUS_CODE.EXPIRED_TOKEN));
            break;
          case API_ADMIN_STATUS_CODE.PASS_WRONG_FORMAT:
            //password wrong format, validation done on bff, not MYL
            dispatch(failure(API_ADMIN_STATUS_CODE.PASS_WRONG_FORMAT));
            dispatch(showError(t('ERROR_PASS_FORMAT2')));
            break;
          default:
            //error not handled --> generic error
            dispatch(failure(API_ADMIN_STATUS_CODE.GENERIC_ERROR));
            dispatch(showError(''));
            break;
        }
      })
      .catch(err => {
        console.error('resetPasswordPublic error', err);
        dispatch(failure('error'));
        dispatch(showError(err));
      });
  };

  function request() {
    return {
      type: actionTypes.RESET_PSW_PUBLIC_REQUEST,
    };
  }
  function success(userType) {
    return {
      type: actionTypes.RESET_PSW_PUBLIC_SUCCESS,
      userType,
    };
  }

  function failure(error) {
    return {
      type: actionTypes.RESET_PSW_PUBLIC_FAILURE,
      error,
    };
  }
};

export const resetPasswordLogged = (
  firstName,
  lastName,
  newPassword,
  emailNotification = null,
  showLeaderboardPosition = null,
  schoolName,
  graduationYear,
  zip,
  learnerName,
  licenseId,
  // field1,
  // field2
) => {
  return (dispatch, getState) => {
    //update user request handler
    let promiseUpdateUser = promiseMock({ data: {} });
    let makeUpdateUserRequest = false;
    let errorUpdateUserRequest = false;
    //make update user request if preferredNotificationChannels or preferredLeaderBoardPresence are valued
    if (
      emailNotification !== null ||
      showLeaderboardPosition !== null ||
      schoolName ||
      graduationYear ||
      zip ||
      learnerName ||
      licenseId !== undefined //||
      // field1 ||
      // field2
    ) {
      makeUpdateUserRequest = true;
      //create new userProfile with new preferredNotificationChannels and preferredLeaderBoardPresence
      let userProfile = { ...getState().user.userProfile };
      if (schoolName) userProfile.schoolName = schoolName;
      //console.log('USERPROFILE UP GRADUATION YEAR', graduationYear);
      if (graduationYear) userProfile.graduationYear = graduationYear;
      if (zip) userProfile.zip = zip;
      if (learnerName) userProfile.learnerName = learnerName;
      if (licenseId !== undefined) userProfile.licenseId = licenseId;
      // if(field1) userProfile.field1 = field1;
      // if(field2) userProfile.field2 = field2;
      if (emailNotification)
        userProfile.preferredNotificationChannels = NOTIFICATION_CHANNELS.ENABLED;
      if (emailNotification === false)
        userProfile.preferredNotificationChannels = NOTIFICATION_CHANNELS.DISABLED;
      if (showLeaderboardPosition)
        userProfile.preferredLeaderBoardPresence = LEADERBOARD_PRESENCE.ENABLED;
      if (showLeaderboardPosition === false)
        userProfile.preferredLeaderBoardPresence = LEADERBOARD_PRESENCE.DISABLED;

      dispatch(requestUpdateUser());

      const urlUpdateUser = urlList.UPDATE_USER_URL;
      promiseUpdateUser = axios.post(urlUpdateUser, userProfile, { headers: getHeaders() });
    }
    const url = urlList.RESET_PSW_LOGGED_URL;
    let payload = {};

    if (firstName) payload.firstName = firstName;
    if (lastName) payload.lastName = lastName;
    if (newPassword) payload.newPassword = newPassword;

    //if changed: request of change password

    dispatch(resetPasswordLoggedRequest());

    //reset password request handler
    let promiseResetPasswordLogged = promiseMock({ data: {} });
    let makeResetPasswordLogged = false;
    //make reset password request if name, surname or password are valued
    if (Object.keys(payload).length > 0) {
      makeResetPasswordLogged = true;
      promiseResetPasswordLogged = axios.post(url, payload, { headers: getHeaders() });
    }

    promiseUpdateUser
      .then(response => {
        if (makeUpdateUserRequest) {
          dispatch(updateUserSuccessHandler(response, 'multiple'));
        }
      })
      .catch(err => {
        if (makeUpdateUserRequest) {
          errorUpdateUserRequest = true;
          dispatch(updateUserFailureHandler(err));
        }
      })
      .finally(() => {
        //after user update --> handle reset password response

        promiseResetPasswordLogged
          .then(response => {
            if (errorUpdateUserRequest) {
              //if update profile failed --> fail
              dispatch(resetPasswordLoggedFailure(''));
              dispatch(showError(''));
            } else if (!makeResetPasswordLogged || response.data.status === 'OK') {
              //if reset password was not requested || reset password is ok --> success
              dispatch(resetPasswordLoggedSuccess());
            } else if (response.data.statusCode === API_ADMIN_STATUS_CODE.PASS_WRONG_FORMAT) {
              console.error('resetPasswordLogged has returned password wrong format', response);
              dispatch(resetPasswordLoggedFailure(''));
              dispatch(showError(getState().utils.lang.ERROR_PASS_FORMAT2));
            } else {
              console.error('resetPasswordLogged has not returned status KO', response);
              dispatch(resetPasswordLoggedFailure(''));
              dispatch(showError(''));
            }
          })
          .catch(err => {
            console.error('resetPasswordLogged error', err);
            dispatch(resetPasswordLoggedFailure('error'));
            dispatch(showError(err));
          });
      });
  };
};

const resetPasswordLoggedRequest = () => {
  return {
    type: actionTypes.RESET_PSW_LOGGED_REQUEST,
  };
};
const resetPasswordLoggedSuccess = () => {
  return {
    type: actionTypes.RESET_PSW_LOGGED_SUCCESS,
  };
};

const resetPasswordLoggedFailure = error => {
  return {
    type: actionTypes.RESET_PSW_LOGGED_FAILURE,
    error,
  };
};

export const resetDataResetPasswordLogged = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_PSW_LOGGED_RESET,
    });
  };
};

export const setCookieWithUserToken = userToken => {
  return dispatch => {
    setCookie(COOKIE.USER_TOKEN, userToken, new Date().getTime() + 1000 * 60 * 60 * 24); //1 day expiration

    //just to check if the user token is correct
    dispatch(getLanguages());

    dispatch(checkAuthenticationStateWithToken(userToken));
  };
};

export const goToAzureToChangePassword = () => {
  let url = urlList.GET_PASSWORD_RESET_AZURE_URL;

  return dispatch => {
    dispatch(resetPasswordLoggedRequest());

    axios({
      url,
      method: 'GET',
      headers: getHeaders(),
    })
      .then(response => {
        if (response.data?.redirectUrl) {
          //redirect to the url set by bff
          window.location.href = response.data?.redirectUrl;
        } else {
          console.error('goToAzureToChangePassword - redirect url not found');
          dispatch(resetPasswordLoggedFailure());
          dispatch(showError(err));
        }
      })
      .catch(err => {
        if (err.response?.status === HTTP_REDIRECT_STATUS && err.response.data.redirectUrl) {
          //redirect to the url set by bff
          window.location.href = err.response?.data?.redirectUrl;
        } else {
          dispatch(resetPasswordLoggedFailure());
          dispatch(showError(err));
        }
      });
  };
};

export const setCookieWithRefreshToken = RefreshToken => {
  /*  return dispatch => {
         setCookie(COOKIE.REFRESH_TOKEN, RefreshToken, (new Date()).getTime() + (1000 * 60 * 60 * 24)); //1 day expiration
 
     } */
  return setCookie(COOKIE.REFRESH_TOKEN, RefreshToken, new Date().getTime() + 1000 * 60 * 60 * 24); //1 day expiration;
};
