import React from 'react';
import Header, { Props as HeaderProps } from './Header';
import Footer from './Footer';
import { LangMapWcag } from '@model/CoursesClass';
import useLangAuth from '@hooks/useLangAuth';

type Props = {
  children: React.ReactNode;
  customBackAction?: () => void;
  hideHeader?: boolean;
  hideFooter?: boolean;
  showBottomMessageFooter?: boolean;
  headerProps?: HeaderProps;
  layoutContainerClassname?: string;
  useDefaultScrollbar?: boolean;
  showBorderBottom ?: boolean;
};

const Layout = (props: Props) => {
  const langKeys: Readonly<(keyof LangMapWcag)[]> = ['SKIP_MAIN_CONTENT'] as const;

  const labels = useLangAuth(langKeys);

  const layoutContent = (
    <>
      <div className={'content-layout-container ' + props.layoutContainerClassname}>
        <div className="content-layout">
          {!props.hideHeader && (
            <Header customBackAction={props.customBackAction} {...props.headerProps} />
          )}
          {props.children}
        </div>

        {!props.hideFooter && <Footer showBottomMessage={props.showBottomMessageFooter} />}
      </div>
    </>
  );

  return (
    <div id="themeManager">
      {props.hideHeader && (
        <section>
        <a
          href="#main"
          aria-label={labels?.SKIP_MAIN_CONTENT}
          className="text-btn-no-arrow skip-main"
        >
          {labels?.SKIP_MAIN_CONTENT}
        </a>
      </section>
      )}
      {layoutContent}
    </div>
  );
};

Layout.defaultProps = {
  layoutContainerClassname: '',
};

export default Layout;
