import { L1Element } from '@model/L1Element';
import lang from '@utility/lang';
import langTP from '@utility/langTP';
import langWcag from '@utility/langWcag';
import langEcomm from '@utility/langEcomm';
import langNotif from '@utility/langNotif';
import { HarvardType } from '@components/Harvard/Harvard';

export type LangMap = Record<keyof typeof lang, string> & LangMapTP & LangMapWcag & LangMapEcomm;
export type LangMapTP = typeof langTP;
export type LangMapWcag = typeof langWcag;
export type LangMapEcomm = typeof langEcomm;
export type LangMapNotif = typeof langNotif;

export enum CourseState{
  UPCOMING = "upcoming",
  SCHEDULED = "scheduled",
  ATTENDED = "attended",
  MISSED = "missed",
  IN_PROGRESS = "inProgress",
  EXPIRING = "expiring",
  OVERDUE = "overdue",
  TO_BEGIN = "toBegin",
  TO_DO = "toDo",
  DONE = "done",
}

export type CourseSessionDates = {
  sessionId: string;
  timeStart: string;
  timeFinish: string;
};

export type CourseSession = {
  id: string;
  allowOverbook?: boolean; //if present use its value otherwise false
//  capacity: number; no more used
  duration: number;
  trainer: string;
  trainers?: Trainer[];
  location: string;
  webExRecWillBe: string;
  //webExRecComplete: string; no more used
  // webExRecCompleteMalus: string; no more used
  allowCancellations?: boolean; //if present use its value otherwise false
  sessionDates: CourseSessionDates[];
  availablePlaces?: number;
  status: string;
  minStartDate: string;
  //faceToFaceId: number; 
  //tags: [];
  language?: string;
  webExSession: string;
  //webExRecShow: string; no more used
  webExRecordingLinks: Array<RecordingLinks>;
  city: null | string;
};

export type UserRelevantSessions = {
  id: string;
  status: string;
  webExRecordingLinks: Array<RecordingLinks>;
  webExSessionLink: string;
  webExSessionStatus: null;
  // faceToFaceId: string; no more used
  faceToFaceAvailable: boolean;
  sessionDates?: CourseSessionDates[];
};

export type RecordingLinks = {
  completionurl: string;
  directurl: string;
}

export class LevelEnriched {
  key: string;
  language: string;
  order: number;
  label: string;
  description: string;
  longDescription?: string;
  imageChipLarge?: string;
}

export class LevelEnrichedFE extends LevelEnriched {
  code?: string;
  // image?: string;
  l1?: LevelEnriched[];
  // type?: 'channel' | 'brand';
  imageIcon: string;
  imageEditorial: string;
  description: string;
  area?: string;
}

export type LevelEnrichedMap = {
  [key: string]: LevelEnrichedFE;
};

export type l2SectionCourse = {
  index: number;
  label: string;
  language: string;
  sectionId: string;
};

export type CourseLanguage = {
  code: string;
  courseId: string;
  description: string;
  isParent: boolean;
  label: string;
  parentId: string;
  value: string;
};

export type CourseDetail = {
  content: Array<CourseActivity>;
  sectionName: string;
  sectionPosition: number;
};

export type CourseActivity = {
  activityPosition: number;
  available: boolean;
  availableFrom: Date;
  availableInfo: string;
  availableUntil: Date;
  completionState: string;
  description: string;
  directUrl: string;
  moduleId: number;
  name: string;
  sectionDescription: string;
  sectionName: string;
  sectionPosition: number;
  tags: Array<string>;
  type: string;
  url: string;
  instance: string;
  // video tracking attributes
  videoCurrentTime: number;
  videoProgress: number;
  videoLastUpdate: number;
};

export interface LPCourseSort {
  cid: Array<string>;
  courseId: string;
  parentId: string;
  rule: string;
  sequence: number;
  //if mandatory=true --> lesson non optional --> LP can be completed only if the lesson is completed
  //if mandatory=false --> lesson optional --> LP can be completed even if the lesson is not sompleted
  mandatory?: boolean;
  optional?: boolean;
  //onboarding topics
  topicKey1?: string;
  topicKey2?: string;
  topicKey3?: string;
  certificate?: string;
}

export class Course {
  // courseVisible: number;
  endDate: string;
  language: string;
  // childCourseId: string;
  courseId: string; //child id
  courseIdMaster: string; //parent id
  courseFullName: string = '';
  courseSummary: string;
  courseOverviewFileParent: string;
  courseOverviewFile: string;
  courseOverviewFileType: string;
  tags: string[];
  // b2bbrand: string[];
  ctype: string[];
  weight: number;
  credits: number;
  duration: number;
  totalLearningPathDuration: number;
  catalogTypes: string[];
  totalLikes: number;
  childCourses?: ChildCourse[];
  learningPath: Array<LPCourseSort>;
  courseSessions: CourseSession[];
  parentCourses: [];
  quickLearning?: boolean; //if present use its value otherwise false
  // fallback: number;
  like: number;
  levelTag?: string[];
  wishlist?: boolean; //if present use its value otherwise false
  isNew?: boolean; //if present use its value otherwise false
  userCourseStatus: string;
  courseCompletionDate: string;
  mandatory?: boolean; //if present use its value otherwise false
  highlight?: boolean; //if present use its value otherwise false
  recommended?: boolean; //if present use its value otherwise false
  level2: Array<string>;
  userRelevantSessions: UserRelevantSessions[];
  feedbackLinks: [];
  certificateLinks: any[]; //certificates present just in the course parent according to the last completed child
  courseStates: Array<CourseState> = [];
  parentLP: Array<{ courseFullName: string; courseId: string; isMaster: boolean }>;
  showJoin: boolean;
  showLiveBadge: boolean;
  showLiveCountdown: boolean;
  startDate: string;
  userCredits: number;
  // selection: number; no more used
  totalLanguages: Array<CourseLanguage>;
  languageToPrint: Array<CourseLanguage>;
  comingSoonLanguages: Array<CourseLanguage>;
  dayLeft: number;
  courseDetails: Array<CourseDetail>;
  numActivities: { totalActivities: number; completedActivities: number };
  isStatic?: boolean;
  teacherImage?: string;
  teacherName?: string;
  fallbackImage?: string;
  affinity: number;
  userRating: number; //rate given by user
  rating: number; //global rating
  rateNr: number; //user that have rated the course
  //added by FE if the course is disabled according to LP rules
  isNotYetAvailable?: boolean;
  complexityId: string;
  complexity: Complexity;
  macroCategory: string;
  expectedCompletion?: string;
  level0: string;
  level0List: string[];
  blockedby?: string[];
  liveInfo?: LiveStreamInfo[];
  vimeoRecNotification?: boolean;
  //ecommerce
  priceable?: boolean;
  price: string; //if free --> price = 0
  fullPrice?: string; //new attribute for el360, full price is the original price of the course
  buyed?: boolean; //if present use its value otherwise false
  // isInPlan: boolean; //for now every priceable course is in plan
  buyedDate: string; //date of purchase
  associatedSession?: CourseAssociatedSession; //inserted by FE for upcoming events visualization
  isPastEvent?: boolean;
  enableWishlist?: boolean;
  planExcluded?: boolean; //ELL-2141
  //onboarding attributes
  onboarding?: boolean; //true if the course is onboarding
  pointerOnboard?: string; //puntatore dell'onboarding
  onboardingOnTheJobTraining?: boolean; //indicates if the app should display the onboarding icon on the job training
  onboardingVirtualClassRoom?: boolean; //indicates if the app should display the onboarding icon VC
  onboardingOneToOne?: boolean; //indicates if the app should display the onboarding icon face to face
  onboardingAssignment?: boolean; //indicates if the app should display the onboarding icon homework
  learningPathInfoTopic1?: { [topic1: string]: string[] }; //map where keys are the topic1 id, the values are the list topic2 contained in the relative topic1
  learningPathInfoTopic3?: InfoTopic3[]; //array of topic3 values
  onboardingChartsInfo?: OnboardingChart; //info to populate onboarding charts
  onboardingToView?: boolean; //if there are two onboardings --> show the one with onboardingToView=true
  completedMandatory?: boolean; //true if all mandatory (no optional) lessons inside onboarding are completed
  isAssignment?: boolean;
  isOneToOne?: boolean;
  isSupportMaterial?: boolean;
  masterAttributes?: MasterAttributesClass = new MasterAttributesClass();
  programExpert?: boolean;
  positionInCarousel?: number; //position of course in carousel
  typeHarvard?: HarvardType;
  certificateChartInfo?: OnboardingChartType; //used to indicate the progress of career programs (build your career)
  isPlan?: boolean;
  //el360?: boolean;  //no attribute for el360 course
  contentId?: number; //contentId for CMS preview
  isInValidityPeriod?: boolean; //LEON 7193
  managerAssignment?: boolean;
}

class MasterAttributesClass {
  //master course attributes
  mcStartDate?: string; //data di inizio master
  mcEndDate?: string; //data di fine master
  mcBuyStartDate?: string; //data di inizio periodo acquisto master
  mcBuyEndDate?: string; //data di fine periodo acquisto master
  mcActiveStartDate?: string; //data di inizio periodo attivazione - non serve a FE
  mcActiveEndDate?: string; //data di fine periodo attivazione - non serve a FE
  mcReservedSpots?: number; //numero posti riservati per VIP - non serve a FE
  availableSubscription?: number; //token ancora disponibili alla vendita - calcolato come mcMaxSubscriptions – numero di token venduti per il master
  mcMaxSubscriptions?: number; //max token vendibili per master - non serve a FE
  mcMinSubscriptions?: number; //numero minimo di iscrizioni per far confermare il master - non serve a FE
  mcConfirmed?: boolean; //if present use its value otherwise false
  planExcluded?: boolean; //if present use its value otherwise false
  teachers?: TrainerExtended[];
  teachersExtendedCMS?: TrainerExtendedCMS[];
}

class Complexity {
  complexity: string;
  id: string;
}

class InfoTopic3 {
  topic2: string;
  topic3: string;
  totalCourses: number = 0;
  inProgressCourses: number = 0;
  doneCourses: number = 0;
  statusCourses: { [courseId: string]: string[] }; //map where the keys are courseId, values are their course status
}

class OnboardingChart {
  total: OnboardingChartType;
  vc: OnboardingChartType;
  lessons: OnboardingChartType;
}

export class OnboardingChartType {
  totalCourses: number = 0;
  inProgressCourses: number = 0;
  bookedCourses: number = 0;
  doneCourses: number = 0;
}

export class ChildCourse {
  childId: string;
  language: string;
  courseFullName?: string;
  courseSummary?: string;
  courseOverviewFile?: string;
  tags?: string[];
  credits?: number;
  duration?: number;
  learningPath?: Array<LPCourseSort>;
  liveInfo?: LiveStreamInfo[];
  courseSessions?: CourseSession[];
  userRelevantSessions?: UserRelevantSessions[];
  certificateLinks: any[]; //use certificateLinks from parent
  vimeoRecNotification?: boolean;
  userCredits?: number;
  courseStates?: Array<string> = [];
}

export class LiveStreamInfo {
  moduleId: string;
  name: string;
  liveUrl: string;
  liveUrlLink: string;
  liveUrlCn: string;
  liveChat: string;
  liveChatLink: string;
  vimeoLiveEventId: string;
  recWillBe: boolean; //if present use its value
  recordingUrls: RecordingUrl[] = []; //contains livestream recordings 1746
  timeStart: string;
  timeEnd: string;
  timestartTimestamp: number;
  timeendTimestamp: number;
  malus: number = 0;
  trainers: Trainer[] = [];
  completionByRecording: boolean; // true if accessing the recording the live will be completed
  // willBeNotifiedLivestreamStart: boolean; //OUT OF SCOPE
  noCompletion: number = 0; //il numero di secondi sotto al quale la live non viene neanche completata
  malusCompletion: number = 0; //il numero di secondi sotto al quale (ma superiore a noCompletion) l'attività viene completata con malus
}

export class RecordingUrl {
  completionUrl: string; //link completation moodle 80%
  directUrl: string; //url to the content
}

export class CourseL1 extends Course {
  l1: L1Element;
  // courseVisible = 1;
  ctype = ['l1'];
  positionInCarousel?: number;
}

export interface CoursesMap {
  [key: string]: Course;
}

export class Trainer {
  firstName?: string;
  lastName?: string;
  picture?: string;
  username: string;
  jobTitle?: string;
  fullName?: string;
}

export class TrainerExtended extends Trainer {
  virtualClassroomIds: string[];
}

export class TrainerExtendedCMS extends TrainerExtended {
  fullName?: string;
  location: string;
  bio: string;
  richText2?: string;
  contentId?: number;
}

export const getTrainerFullName = (trainer: Trainer | TrainerExtended): string => {
  if (!trainer) {
    return '';
  }

  const firstName = (trainer as any).firstname || trainer.firstName;
  const lastName = (trainer as any).lastname || trainer.lastName;
  const fullName = (trainer as any).fullName || trainer.fullName;

  if(fullName) return fullName;
  else{
    return (
      (firstName ? firstName : '') + (firstName && lastName ? ' ' : '') + (lastName ? lastName : '')
    );
  }
};

// export type Props = {
//   lang: LangMap;
//   isLoadingCourses: boolean;
//   courses: Course[];
//   isGray: boolean;
//   title: string;
//   classProps: string;
// }

export class CourseAssociatedSession {
  id: string;
  trainerFirstname: string;
  trainerLastname: string;
  location: string;
  timeStart: string;
  timeFinish: string;
  isBooked: boolean = false;
  showJoin: boolean = false;
  showLiveBadge: boolean = false;
  showLiveCountdown: boolean = false;
  isSessionPassed: boolean = false;
  showPlayButton: boolean = false;
  relevantSession: UserRelevantSessions;
  isAttendedSession: boolean = false;
}

export const COMPLEXITY_ID = {
  ONBOARDING: 'onboarding',
};
