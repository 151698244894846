import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/Api';
import lang from '../../utility/lang';
import langNotif from '../../utility/langNotif';
import { GRADIENT_LIGHT_BLUE_POSITION } from '../../utility/gradient-utils';
import { GradientStyle } from '@model/GradientClass';
import { SORTBY } from '@utility/const';
import { CelebrationModalInfo } from '@model/CelebrationModel';
import { getUserRelevantSessions } from '../actions/utilsActions';

export const initialState = {
  showError: false,
  error: null,
  //LABELS
  lang,
  labelLang: null,
  langNotif,
  labelLangNotif: null,
  labelLangTP: null,
  labelL0Map: {},
  labelL1Map: {},
  labelL2Map: {},
  labelLtTagMap: {},
  labelComplexityMap: {},
  labelOnboardingTopicsMap: {},
  //PERSONALIZATION SURVEY
  isPersonalizationVisible: false,
  isLoadingPersonalization: false,
  personalizationQuestions: null,
  isSendingPersonalization: false,
  //RATING SURVEY
  isLoadingRating: false,
  ratingQuestions: null,
  isSendingRating: false,
  sendRatingSuccess: false,
  //REVIEW SURVEY
  isLoadingReview: false,
  reviewQuestions: null,
  isSendingReview: false,
  sendReviewSuccess: false,
  brandsList: {
    houseBrands: [],
    licensedBrands: [],
    thirdPartyBrands: [],
    lensTechnologies: [],
  },
  timezones: [],
  isLoadingTimezones: false,

  //NOTICE POPUP
  noticeTimeout: 5000,
  subScriptionTimeOutFunc: null,
  notices: [],

  languages: [],
  printLanguagesMap: {},
  isLoadingLanguages: false,
  filterLabels: {
    new: '',
    booked: '',
    inProgress: '',
    toDo: '',
    expiring: '',
    overdue: '',
    done: '',
    upcoming: '',
    missed: '',
    scheduled: '',
    attended: '',
    wishlist: '',
    mandatory: '',
    classes: '',
    onlineCourses: '',
    learningPath: '',
    video: '',
    vimeo: '',
    Rating: '',
    Mandatory: '',
    New: '',
    Relevance: '',
    Shortest: '',
    Title_AZ: '',
    Title_ZA: '',
    recommended: ''
  },
  tabHome: 0,
  subTabHome: 0,
  isGetBrandsCompleted: false,
  isLoadingGetUserRelevantSessions: false,
  isLoadingGetUserRelevantSessionsById: false,
  isLoadedGetUserRelevantSessionsById: false,
  //iframe
  showIframe: false,
  urlIframe: null,
  urlToCheckIframe: null,
  isIframeVideo: false,
  courseIframe: null,
  activityIdIframe: '',
  subtitleCodeIframe: '',
  urlsLivestream: null,
  autoManageHistoryIframeOnClose: true,

  //sort by relevance
  isLoadingSortByRelevance: false,
  isLoadedSortByRelevance: false,
  sortByRelevanceCriteria: [],

  //url to redirect after login
  urlRedirectAfterLogin: null,

  colorPickerValue: GRADIENT_LIGHT_BLUE_POSITION.value,
  gradientStyle: new GradientStyle(GRADIENT_LIGHT_BLUE_POSITION.value),
  leaderboardPresence: true,
  notificationChannels: true,
  catalogueDocTitle: '',

  openMenu: false,

  hotL1: null,
  isLoadingHotL1: false,

  //l1Map is equal to labelL1Map but
  //l1Map contains just L1 visible to the user according to its catalog
  //labelL1Map contains all possible L1
  l1Map: {},
  //show label preview
  showLabelPreviewBanner: false,
  //show CMS preview
  showCMSPreviewBanner: false,
  showModalSessionExpired: false,
  showCollectionPreviewBanner: false,
  //prompt method for PWA
  promptMethod: null,
  //onboarding celebration modal
  celebrationModalInfo: new CelebrationModalInfo(),
  //flag for downloading onboarding certificate pdf
  isDownloadingOnboardingCertificatePdf: false,
  //show area of expertise
  showAreaOfExpertise: false,
  showVisionCareMenu: false,
  showCollectionsMenu: false,
  showEdPathMenu: false,
  typeModal: null,
  areaOfExpertiseL1MenuL0Id: '',
  showModalContactCustomerService: false,
  showModalRequestJoin: false,
  filteredTeacher: null,
  sendingAnalytics: true,
  forceAnalytics: false,
  analyticsCourseId: '',
  // TODO to remove mock
  //usergroup
  userSimulated: null,
  showLabelPersonificationBanner: false,
  showDialogUserGroupSimulationPage: false,
  alreadyInSimulation: false,
  hideBannerWasClicked: false,
  exitBannerWasClicked: false,
  showDialogUserGroupSimulationHide: false,
  lpInSavingProgress: {},
  linksHeaderFromCMS: [],
  hideLogin: true,
  hideSignup: true,
  windowLocationSearchQuery: '',
  isLoadingGetFiltersCertificate: false,
  isGetFiltersCertificateFinished: false,
  modalsV3Open: {},
};
const setSendingAnalytics = (state, action) => {
  return updateObject(state, {
    sendingAnalytics: action.sendingAnalytics
  });
}

const setForceAnalytics = (state, action) => {
  return updateObject(state, {
    forceAnalytics: action.forceAnalytics
  });
}

const setAnalyticsCourseId = (state, action) => {
  return updateObject(state, {
    analyticsCourseId: action.analyticsCourseId
  });
}

const setWindowLocationSearchQuery = (state, action) => {
  return updateObject(state, {
    windowLocationSearchQuery: action.windowLocationSearchQuery
  });
}
const showErrorMessage = (state, action) => {
  return updateObject(state, {
    showError: true,
    error: action.message,
  });
};

const resetError = state => {
  return updateObject(state, {
    showError: false,
    error: null,
  });
};

const saveLabel = (state, action) => {
  return updateObject(state, {
    lang: action.lang,
    filterLabels: {
      new: action.lang.NEW_LABEL,
      booked: action.lang.FILTER_STATUS_BOOKED,
      inProgress: action.lang.IN_PROGRESS_LABEL,
      toDo: action.lang.PROFILE_TO_DO,
      expiring: action.lang.EXPIRING_LABEL,
      overdue: action.lang.OVERDUE_LABEL,
      done: action.lang.COMPLETED_LABEL,
      upcoming: action.lang.UPCOMING_LABEL,
      missed: action.lang.MISSED_LABEL,
      scheduled: action.lang.SCHEDULED_LABEL,
      attended: action.lang.ATTENDED_LABEL,
      toBeStarted: action.lang.FILTER_STATUS_TO_BE_STARTED,
      toBeContinued: action.lang.FILTER_STATUS_TO_BE_CONTINUED,
      completed: action.lang.FILTER_STATUS_COMPLETED,
      wishlist: action.lang.WISHLIST_PAGE_NAME_LABEL,
      mandatory: action.lang.MANDATORY_LABEL,
      liveNow: action.lang.LIVESTREAM_NOW,
      [SORTBY.RATING]: action.lang.MOST_LIKE_LABEL,
      Mandatory: action.lang.MANDATORY_LABEL,
      New: action.lang.NEWEST_LABEL,
      Relevance: action.lang.RELEVANCE_LABEL,
      Shortest: action.lang.SHORTEST_LABEL,
      Title_AZ: action.lang.TITLE_AZ_LABEL,
      Title_ZA: action.lang.TITLE_ZA_LABEL,
      recommended: action.lang.RECOMMENDED_IN_TODO_TITLE
    },
    labelLang: action.labelLang ? action.labelLang : state.labelLang,
    labelLangTP: action.labelLangTP ? action.labelLangTP : state.labelLangTP,
  });
};

const saveLabelNotif = (state, action) => {
  return updateObject(state, {
    langNotif: action.lang,
    labelLangNotif: action.labelLang,
  });
};

const getLabelsL0 = (state, action) => {
  return updateObject(state, {
    labelL0Map: action.labelL0Map,
  });
};

const getLabelsL1 = (state, action) => {
  return updateObject(state, {
    labelL1Map: action.labelL1Map,
  });
};

const getLabelsL2 = (state, action) => {
  return updateObject(state, {
    labelL2Map: action.labelL2Map,
  });
};

const getLabelLtTag = (state, action) => {
  return updateObject(state, {
    labelLtTagMap: action.labelLtTagMap,
  });
};

const getLabelsComplexity = (state, action) => {
  return updateObject(state, {
    labelComplexityMap: action.labelComplexityMap,
  });
};

const getLabelOnboardingTopics = (state, action) => {
  return updateObject(state, {
    labelOnboardingTopicsMap: action.labelOnboardingTopicsMap,
  });
};

const showPersonalization = (state, action) => {
  return updateObject(state, {
    isPersonalizationVisible: action.value,
  });
};

const getPersonalizationQuestionsRequest = state => {
  return updateObject(state, {
    isLoadingPersonalization: true,
  });
};

const getPersonalizationQuestionsSuccess = (state, action) => {
  const questions = action.questions;

  //sort questions
  questions.survey.questions.sort((a, b) => a.questionOrder - b.questionOrder);

  return updateObject(state, {
    isLoadingPersonalization: false,
    personalizationQuestions: questions,
  });
};

const getPersonalizationQuestionsFailure = state => {
  return updateObject(state, {
    isLoadingPersonalization: false,
    personalizationQuestions: null,
  });
};

const sendPersonalizationAnswersRequest = state => {
  return updateObject(state, {
    isSendingPersonalization: true,
  });
};

const sendPersonalizationAnswersSuccess = state => {
  return updateObject(state, {
    isSendingPersonalization: false,
    personalizationQuestions: null,
  });
};

const sendPersonalizationAnswersFailure = state => {
  return updateObject(state, {
    isSendingPersonalization: false,
  });
};

const getRatingQuestionsRequest = state => {
  return updateObject(state, {
    isLoadingRating: true,
    ratingQuestions: null,
  });
};

const getRatingQuestionsSuccess = (state, action) => {
  const questions = action.questions;

  //sort questions
  questions.survey.questions.sort((a, b) => a.questionOrder - b.questionOrder);

  return updateObject(state, {
    isLoadingRating: false,
    ratingQuestions: questions,
  });
};

const getRatingQuestionsFailure = state => {
  return updateObject(state, {
    isLoadingRating: false,
    ratingQuestions: null,
  });
};

const sendRatingAnswersRequest = state => {
  return updateObject(state, {
    isSendingRating: true,
    sendRatingSuccess: false,
  });
};

const sendRatingAnswersSuccess = state => {
  return updateObject(state, {
    isSendingRating: false,
    sendRatingSuccess: true,
    ratingQuestions: null,
  });
};

const sendRatingAnswersFailure = state => {
  return updateObject(state, {
    isSendingRating: false,
    sendRatingSuccess: false,
  });
};

const resetRatingSurvey = state => {
  return updateObject(state, {
    isSendingRating: false,
    sendRatingSuccess: false,
  });
};

const getReviewQuestionsRequest = state => {
  return updateObject(state, {
    isLoadingReview: true,
    reviewQuestions: null,
  });
};

const getReviewQuestionsSuccess = (state, action) => {
  const questions = action.questions;

  //sort questions
  questions.survey.questions.sort((a, b) => a.questionOrder - b.questionOrder);

  return updateObject(state, {
    isLoadingReview: false,
    reviewQuestions: questions,
  });
};

const getReviewQuestionsFailure = state => {
  return updateObject(state, {
    isLoadingReview: false,
    reviewQuestions: null,
  });
};

const sendReviewAnswersRequest = state => {
  return updateObject(state, {
    isSendingReview: true,
    sendReviewSuccess: false,
  });
};

const sendReviewAnswersSuccess = state => {
  return updateObject(state, {
    isSendingReview: false,
    sendReviewSuccess: true,
    reviewQuestions: null,
  });
};

const sendReviewAnswersFailure = state => {
  return updateObject(state, {
    isSendingReview: false,
    sendReviewSuccess: false,
  });
};

const resetReviewSurvey = state => {
  return updateObject(state, {
    isSendingReview: false,
    sendReviewSuccess: false,
  });
};

const logout = state => {
  let initialStateButRedirect = {
    ...initialState,
    urlRedirectAfterLogin: state.urlRedirectAfterLogin,
    promptMethod: state.promptMethod,
    // States below need to remain even after logout
    linksHeaderFromCMS: state.linksHeaderFromCMS,
    hideLogin: state.hideLogin,
    hideSignup: state.hideSignup,
  };
  return initialStateButRedirect;
};

const getBrandsSuccess = (state, action) => {
  return updateObject(state, {
    brandsList: action.brandsList,
    isGetBrandsCompleted: true,
  });
};

const getUserRelevantSessionsCompleted = (state, action) => {
  return updateObject(state, {
    isLoadingGetUserRelevantSessions: false
  });
}

const getUserRelevantSessionsByIdCompleted = (state, action) => {
  return updateObject(state, {
    isLoadingGetUserRelevantSessionsById: false
  });
}

const getUserRelevantSessionsRequest = (state) => {
  return updateObject(state, {
    isLoadingGetUserRelevantSessions: true
  });
}

const getUserRelevantSessionsByIdRequest = (state, action) => {
  return updateObject(state, {
    isLoadingGetUserRelevantSessionsById: true
  });
}

const getUserRelevantSessionsFailed = (state, action) => {
  return updateObject(state, {
    isLoadingGetUserRelevantSessions: false
  });
}

const getUserRelevantSessionsByIdFailed = (state, action) => {
  return updateObject(state, {
    isLoadingGetUserRelevantSessionsById: false
  });
}

const UserRelevantSessionsByIdCompletedLoaded = (state, action) => {
  return updateObject(state, {
    isLoadedGetUserRelevantSessionsById: true
  });
}

const UserRelevantSessionsByIdRequestLoaded = (state, action) => {
  return updateObject(state, {
    isLoadedGetUserRelevantSessionsById: false
  });
}

const UserRelevantSessionsByIdFailedLoaded = (state, action) => {
  return updateObject(state, {
    isLoadedGetUserRelevantSessionsById: false
  });
}

const getTimezonesRequest = state => {
  return updateObject(state, {
    isLoadingTimezones: true,
  });
};


const getTimezonesSuccess = (state, action) => {
  return updateObject(state, {
    timezones: action.timezones,
    isLoadingTimezones: false,
  });
};

const getTimezonesFailure = state => {
  return updateObject(state, {
    isLoadingTimezones: false,
  });
};

const showNotice = (state, action) => {
  return updateObject(state, {
    notices: action.notices,
  });
};

const hideNotice = (state, action) => {
  let notices = [...state.notices];

  for (let i = 0; i < state.notices.length; i++) {
    if (state.notices[i].noticeId === action.notice.noticeId) {
      notices.splice(i, 1);
      break;
    }
  }

  return updateObject(state, {
    notices,
  });
};

const getLanguagesRequest = state => {
  return updateObject(state, {
    isLoadingLanguages: true,
  });
};

const getLanguagesSuccess = (state, action) => {
  return updateObject(state, {
    languages: action.languages,
    printLanguagesMap: action.printLanguagesMap,
    isLoadingLanguages: false,
  });
};

const getLanguagesFailure = state => {
  return updateObject(state, {
    isLoadingLanguages: false,
  });
};

const setSubTabHome = (state, action) => {
  return updateObject(state, {
    subTabHome: action.subTabHome,
  });
};

const setTabHome = (state, action) => {
  return updateObject(state, {
    tabHome: action.tabHome,
  });
};

const setSubscriptionTimeoutFunc = (state, action) => {
  return updateObject(state, {
    subScriptionTimeOutFunc: action.subScriptionTimeOutFunc,
  });
};

const clearTimeout = state => {
  return updateObject(state, {
    subScriptionTimeOutFunc: null,
  });
};

const toggleIframePopup = (state, action) => {
  return updateObject(state, {
    showIframe: action.value,
    urlIframe: action.url,
    urlToCheckIframe: action.urlToCheck,
    isIframeVideo: action.isVideo,
    courseIframe: action.value ? action.course : null,
    urlsLivestream: action.urlsLivestream,
    activityIdIframe: action.value ? action.activityIdIframe : '',
    subtitleCodeIframe: action.value ? action.subtitleCode : '',
  });
};

const getSortByRelevanceRequest = state => {
  return updateObject(state, {
    isLoadingSortByRelevance: true,
  });
};

const getSortByRelevanceSuccess = (state, action) => {
  return updateObject(state, {
    sortByRelevanceCriteria: action.sortByRelevanceCriteria,
    isLoadingSortByRelevance: false,
    isLoadedSortByRelevance: true,
  });
};

const getSortByRelevanceFailure = state => {
  return updateObject(state, {
    isLoadingSortByRelevance: false,
    isLoadedSortByRelevance: true,
  });
};

const setUrlRedirectAfterLogin = (state, action) => {
  console.log('setUrlRedirectAfterLogin', action.url);
  return updateObject(state, {
    urlRedirectAfterLogin: action.url,
  });
};

const setColorPicker = (state, action) => {
  return updateObject(state, {
    colorPickerValue: action.colorPickerValue,
  });
};

const setGradientStyle = (state, action) => {
  return updateObject(state, {
    gradientStyle: action.gradientStyle,
  });
};

const setLeaderboardPresence = (state, action) => {
  return updateObject(state, {
    leaderboardPresence: action.leaderboardPresence,
  });
};

const setNotificationChannels = (state, action) => {
  return updateObject(state, {
    notificationChannels: action.notificationChannels,
  });
};

const setCatalogueDocTitle = (state, action) => {
  return updateObject(state, {
    catalogueDocTitle: action.catalogueDocTitle,
  });
};

const setOpenMenu = (state, action) => {
  return updateObject(state, {
    openMenu: action.open,
  });
};

const getHotL1Request = state => {
  return updateObject(state, {
    isLoadingHotL1: true,
  });
};

const getHotL1Success = (state, action) => {
  return updateObject(state, {
    isLoadingHotL1: false,
    hotL1: action.hotL1,
  });
};

const getHotL1Failure = state => {
  return updateObject(state, {
    isLoadingHotL1: false,
  });
};

const getL1Map = (state, action) => {
  // console.log('l1Map', action.l1Map);
  return updateObject(state, {
    l1Map: action.l1Map,
  });
};

const setShowLabelPreviewBanner = (state, action) => {
  return updateObject(state, {
    showLabelPreviewBanner: action.value,
  });
};

const setShowCMSPreviewBanner = (state, action) => {
  return updateObject(state, {
    showCMSPreviewBanner: action.value,
  });
};

const setShowModalExpiredSession= (state, action) => {
  return updateObject(state, {
    showModalSessionExpired: action.value,
  });
}

const setShowCollectionPreviewBanner = (state, action) => {
  return updateObject(state, {
    showCollectionPreviewBanner: action.value,
  });
};

const setPromptMethod = (state, action) => {
  return updateObject(state, {
    promptMethod: action.promptMethod,
  });
};

const setShowCelebrationModal = (state, action) => {
  return updateObject(state, {
    celebrationModalInfo: {
      show: action.show,
      courseId: !!action.show ? action.courseId : '',
    },
  });
};

const startDownloadCelebrationPdf = state => {
  return updateObject(state, {
    isDownloadingOnboardingCertificatePdf: true,
  });
};

const endDownloadCelebrationPdf = state => {
  return updateObject(state, {
    isDownloadingOnboardingCertificatePdf: false,
  });
};

const setShowAreaOfExpertiseModal = (state, action) => {
  return updateObject(state, {
    showAreaOfExpertise: action.show,
  });
};

const setShowVsionCareMenuModal = (state, action) => {
  return updateObject(state, {
    showVisionCareMenu: action.show,
  });
};

const setShowCollectionsMenuModal = (state, action) => {
  return updateObject(state, {
    showCollectionsMenu: action.show,
  });
};

const setShowEdPathMenuModal = (state, action) => {
  return updateObject(state, {
    showEdPathMenu: action.show,
  });
};

const setShowModalByType = (state, action) => {
  return updateObject(state, {
    typeModal: action.value,
    areaOfExpertiseL1MenuL0Id: action.areaOfExpertiseL1MenuL0Id,
  });
};

const setAutoManageHistoryIframeOnClose = (state, action) => {
  return updateObject(state, {
    autoManageHistoryIframeOnClose: action.autoManageHistoryIframeOnClose
  });
};

const setShowModalContactCustomerService = (state, action) => {
  return updateObject(state, {
    showModalContactCustomerService: action.show,
  });
};

const setShowModalRequestJoin = (state, action) => {
  return updateObject(state, {
    showModalRequestJoin: action.show,
  });
};

const setTeacherUsername = (state, action) => {
  return updateObject(state, {
    filteredTeacher: action.value,
  });
};

// UserGroupSimulation
const setUserSimulated = (state, action) => {
  return updateObject(state, {
    userSimulated: action.value,
  });
};

// const setAlreadyInSimulation = (state, action) => {
//   return updateObject(state, {
//     alreadyInSimulation: action.value,
//   });
// };

const setHideBannerWasClicked = (state, action) => {
  return updateObject(state, {
    hideBannerWasClicked: action.value,
  });
};

const setExitBannerWasClicked = (state, action) => {
  return updateObject(state, {
    exitBannerWasClicked: action.value,
  });
};

const setShowLaberPersonificationBanner = (state, action) => {
  return updateObject(state, {
    showLabelPersonificationBanner: action.value,
  });
};
const setShowDialogUserGroupSimulationPage = (state, action) => {
  return updateObject(state, {
    showDialogUserGroupSimulationPage: action.value,
  });
};
const setShowDialogUserGroupSimulationHide = (state, action) => {
  return updateObject(state, {
    showDialogUserGroupSimulationHide: action.value,
  });
};
const setLPInSavingProgress = (state, action) => {
  // debugger
  const tempLpInSavingProgress = state.lpInSavingProgress
  const courseId = action.value.courseId
  const lpId = action.value.lpId

  // if lp is not already in progress, set in progress
  if (!Object.keys(tempLpInSavingProgress).includes(courseId)) {
    tempLpInSavingProgress[courseId] = lpId
  }


  return updateObject(state, {
    lpInSavingProgress: tempLpInSavingProgress,
  });
};

const unsetLPInSavingProgress = (state, action) => {

  let tempLpInSavingProgress = state.lpInSavingProgress
  const courseId = action.value.courseId

  // if lp is in progress remove it
  if (tempLpInSavingProgress[courseId]) {
    delete tempLpInSavingProgress[courseId]
  }

  return updateObject(state, {
    lpInSavingProgress: tempLpInSavingProgress,
  });
};

const setLinksHeaderFromCMS = (state, action) => {
  return updateObject(state, {
    linksHeaderFromCMS: action.links,
  });
};

const setVisibleBtnHeaderFromCMS = (state, action) => {
  const hideButtons = action.buttonsFromCMS || [];

  const hideLogin = hideButtons?.some(button => button.externalReference === "no-login");
  const hideSignup = hideButtons?.some(button => button.externalReference === "no-signup");

  return updateObject(state, {
    hideLogin: hideLogin,
    hideSignup: hideSignup,
  });

};


const getUsergroupSimulationFiltersSuccess = (state, action) => {
  return updateObject(state, {
    usergroupSimulationFilters: action.value
  })
};

const getUsergroupSimulationFiltersFailure = (state, action) => {
  return updateObject(state, {
    usergroupSimulationFilters: action.value
  })
};


const getCertificatesFilterCompleted = (state, action) => {
  return updateObject(state, {
    isLoadingGetFiltersCertificate: false,
    isGetFiltersCertificateFinished: true,
  });
}

const getCertificatesFilterRequest = (state) => {
  return updateObject(state, {
    isLoadingGetFiltersCertificate: true,
    isGetFiltersCertificateFinished: false,
  });
}

const getCertificatesFilterFailed = (state, action) => {
  return updateObject(state, {
    isLoadingGetFiltersCertificate: false,
    isGetFiltersCertificateFinished: true,
  });
}

const setModalsV3Open = (state, action) => {
  const modalsOpen = { ...state.modalsV3Open, ...action.value };
  return {
    ...state,
    modalsV3Open: modalsOpen   
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ERROR_MESSAGE:
      return showErrorMessage(state, action);
    case actionTypes.HIDE_ERROR_MESSAGE:
      return resetError(state);
    case actionTypes.GET_LABEL_SUCCESS:
      return saveLabel(state, action);
    case actionTypes.GET_LABEL_NOTIF_SUCCESS:
      return saveLabelNotif(state, action);
    case actionTypes.GET_LABEL_L0_SUCCESS:
      return getLabelsL0(state, action);
    case actionTypes.GET_LABEL_L1_SUCCESS:
      return getLabelsL1(state, action);
    case actionTypes.GET_LABEL_L2_SUCCESS:
      return getLabelsL2(state, action);
    case actionTypes.GET_LABEL_LTTAG_SUCCESS:
      return getLabelLtTag(state, action);
    case actionTypes.GET_LABEL_COMPLEXITY_SUCCESS:
      return getLabelsComplexity(state, action);
    case actionTypes.GET_LABEL_ONBOARDING_TOPICS_SUCCESS:
      return getLabelOnboardingTopics(state, action);
    case actionTypes.SHOW_PERSONALIZATION:
      return showPersonalization(state, action);
    case actionTypes.GET_PERSONALIZATION_REQ:
      return getPersonalizationQuestionsRequest(state);
    case actionTypes.GET_PERSONALIZATION_SUCCESS:
      return getPersonalizationQuestionsSuccess(state, action);
    case actionTypes.GET_PERSONALIZATION_FAILURE:
      return getPersonalizationQuestionsFailure(state);
    case actionTypes.SEND_PERSONALIZATION_REQ:
      return sendPersonalizationAnswersRequest(state);
    case actionTypes.SEND_PERSONALIZATION_SUCCESS:
      return sendPersonalizationAnswersSuccess(state);
    case actionTypes.SEND_PERSONALIZATION_FAILURE:
      return sendPersonalizationAnswersFailure(state);
    case actionTypes.GET_RATING_REQ:
      return getRatingQuestionsRequest(state);
    case actionTypes.GET_RATING_SUCCESS:
      return getRatingQuestionsSuccess(state, action);
    case actionTypes.GET_RATING_FAILURE:
      return getRatingQuestionsFailure(state);
    case actionTypes.SEND_RATING_REQ:
      return sendRatingAnswersRequest(state);
    case actionTypes.SEND_RATING_SUCCESS:
      return sendRatingAnswersSuccess(state);
    case actionTypes.SEND_RATING_FAILURE:
      return sendRatingAnswersFailure(state);
    case actionTypes.RESET_RATING_SURVEY:
      return resetRatingSurvey(state);
    case actionTypes.GET_REVIEW_REQ:
      return getReviewQuestionsRequest(state);
    case actionTypes.GET_REVIEW_SUCCESS:
      return getReviewQuestionsSuccess(state, action);
    case actionTypes.GET_REVIEW_FAILURE:
      return getReviewQuestionsFailure(state);
    case actionTypes.SEND_REVIEW_REQ:
      return sendReviewAnswersRequest(state);
    case actionTypes.SEND_REVIEW_SUCCESS:
      return sendReviewAnswersSuccess(state);
    case actionTypes.SEND_REVIEW_FAILURE:
      return sendReviewAnswersFailure(state);
    case actionTypes.RESET_REVIEW_SURVEY:
      return resetReviewSurvey(state);
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.GET_BRANDS_SUCCESS:
      return getBrandsSuccess(state, action);
    case actionTypes.GET_USER_RELEVANT_SESSION_COMPLETED:
      return getUserRelevantSessionsCompleted(state, action);
    case actionTypes.GET_USER_RELEVANT_SESSION_BY_ID_COMPLETED:
      return getUserRelevantSessionsByIdCompleted(state, action);
    case actionTypes.GET_USER_RELEVANT_SESSION_REQUEST:
      return getUserRelevantSessionsRequest(state, action);
    case actionTypes.GET_USER_RELEVANT_SESSION_BY_ID_REQUEST:
      return getUserRelevantSessionsByIdRequest(state, action);
    case actionTypes.GET_USER_RELEVANT_SESSION_FAILED:
      return getUserRelevantSessionsFailed(state, action);
    case actionTypes.GET_USER_RELEVANT_SESSION_BY_ID_FAILED:
      return getUserRelevantSessionsByIdFailed(state, action);
    case actionTypes.USER_RELEVANT_SESSION_BY_ID_COMPLETED_LOADED:
      return UserRelevantSessionsByIdCompletedLoaded(state, action);
    case actionTypes.USER_RELEVANT_SESSION_BY_ID_REQUEST_LOADED:
      return UserRelevantSessionsByIdRequestLoaded(state, action);
    case actionTypes.USER_RELEVANT_SESSION_BY_ID_FAILED_LOADED:
      return UserRelevantSessionsByIdFailedLoaded(state, action);
    case 'SET_IS_LOADED_GET_USER_RELEVANT_SESSIONS_BY_ID':
      return {
        ...state,
        isLoadedGetUserRelevantSessionsById: action.payload,
      };
    case actionTypes.GET_TIMEZONE_REQUEST:
      return getTimezonesRequest(state);
    case actionTypes.GET_TIMEZONE_SUCCESS:
      return getTimezonesSuccess(state, action);
    case actionTypes.GET_TIMEZONE_FAILURE:
      return getTimezonesFailure(state);
    case actionTypes.SHOW_NOTICE:
      return showNotice(state, action);
    case actionTypes.HIDE_NOTICE:
      return hideNotice(state, action);
    case actionTypes.GET_LANGUAGES_REQUEST:
      return getLanguagesRequest(state);
    case actionTypes.GET_LANGUAGES_SUCCESS:
      return getLanguagesSuccess(state, action);
    case actionTypes.GET_LANGUAGES_FAILURE:
      return getLanguagesFailure(state);
    case actionTypes.SET_SUB_TAB_HOME:
      return setSubTabHome(state, action);
    case actionTypes.SET_TAB_HOME:
      return setTabHome(state, action);
    case actionTypes.SET_SUBSCRIPTION_TIMEOUT_FUNC:
      return setSubscriptionTimeoutFunc(state, action);
    case actionTypes.CLEAR_SUBSCRIPTION_TIME_OUT:
      return clearTimeout(state);
    case actionTypes.TOGGLE_IFRAME_POPUP:
      return toggleIframePopup(state, action);
    case actionTypes.GET_SORT_BY_RELEVANCE_REQUEST:
      return getSortByRelevanceRequest(state);
    case actionTypes.GET_SORT_BY_RELEVANCE_SUCCESS:
      return getSortByRelevanceSuccess(state, action);
    case actionTypes.GET_SORT_BY_RELEVANCE_FAILURE:
      return getSortByRelevanceFailure(state);
    case actionTypes.SET_URL_REDIRECT_AFTER_LOGIN:
      return setUrlRedirectAfterLogin(state, action);
    case actionTypes.SET_COLOR_PICKER:
      return setColorPicker(state, action);
    case actionTypes.SET_GRADIENT_STYLE:
      return setGradientStyle(state, action);
    case actionTypes.SET_LEADERBOARD_PRESENCE:
      return setLeaderboardPresence(state, action);
    case actionTypes.SET_NOTIFICATION_CHANNELS:
      return setNotificationChannels(state, action);
    case actionTypes.SET_CATALOGUE_DOC_TITLE:
      return setCatalogueDocTitle(state, action);
    case actionTypes.SET_OPEN_MENU:
      return setOpenMenu(state, action);
    case actionTypes.GET_HOT_L1_REQUEST:
      return getHotL1Request(state);
    case actionTypes.GET_HOT_L1_SUCCESS:
      return getHotL1Success(state, action);
    case actionTypes.GET_HOT_L1_FAILURE:
      return getHotL1Failure(state);
    case actionTypes.GET_L1_MAP:
      return getL1Map(state, action);
    case actionTypes.SET_SHOW_LABEL_PREVIEW:
      return setShowLabelPreviewBanner(state, action);
    case actionTypes.SET_SHOW_CMS_PREVIEW:
      return setShowCMSPreviewBanner(state, action);
    case actionTypes.SET_SHOW_MODAL_SESSION_EXPIRED:
      return setShowModalExpiredSession(state,action);
    case actionTypes.SET_COLLECTION_PREVIEW:
      return setShowCollectionPreviewBanner(state, action);
    case actionTypes.SET_PROMPT_METHOD:
      return setPromptMethod(state, action);
    case actionTypes.SET_SHOW_CELEBRATION_MODAL:
      return setShowCelebrationModal(state, action);
    case actionTypes.GET_ONBOARDING_CERTIFICATE_PDF_REQUEST:
      return startDownloadCelebrationPdf(state);
    case actionTypes.GET_ONBOARDING_CERTIFICATE_PDF_END:
      return endDownloadCelebrationPdf(state);
    case actionTypes.SET_SHOW_AREA_OF_EXPERTISE:
      return setShowAreaOfExpertiseModal(state, action);
    case actionTypes.SET_SHOW_COLLECTIONS_MENU_MODAL:
      return setShowCollectionsMenuModal(state, action);
    case actionTypes.SET_SHOW_ED_PATH_MENU_MODAL:
      return setShowEdPathMenuModal(state, action);
    case actionTypes.SET_SHOW_MODAL_BY_TYPE:
      return setShowModalByType(state, action);
    case actionTypes.SET_SHOW_MODAL_CONTACT_CUSTOMER_SERVICE:
      return setShowModalContactCustomerService(state, action);
    case actionTypes.SET_SHOW_MODAL_REQUEST_JOIN:
      return setShowModalRequestJoin(state, action);
    case actionTypes.SET_TEACHER_USERNAME:
      return setTeacherUsername(state, action);
    case actionTypes.SET_SENDING_ANDALYTICS: return setSendingAnalytics(state, action);
    case actionTypes.SET_FORCE_ANALYTICS: return setForceAnalytics(state, action);
    case actionTypes.SET_ANALYTICS_COURSE_ID: return setAnalyticsCourseId(state, action);
    case actionTypes.SET_LOCATION_SEARCH_QUERY: return setWindowLocationSearchQuery(state, action);
    case actionTypes.SHOW_ERROR_MESSAGE: return showErrorMessage(state, action);
    case actionTypes.HIDE_ERROR_MESSAGE: return resetError(state);
    case actionTypes.GET_LABEL_SUCCESS: return saveLabel(state, action);
    case actionTypes.GET_LABEL_NOTIF_SUCCESS: return saveLabelNotif(state, action);
    case actionTypes.GET_LABEL_L0_SUCCESS: return getLabelsL0(state, action);
    case actionTypes.GET_LABEL_L1_SUCCESS: return getLabelsL1(state, action);
    case actionTypes.GET_LABEL_L2_SUCCESS: return getLabelsL2(state, action);
    case actionTypes.GET_LABEL_LTTAG_SUCCESS: return getLabelLtTag(state, action);
    case actionTypes.GET_LABEL_COMPLEXITY_SUCCESS: return getLabelsComplexity(state, action);
    case actionTypes.GET_LABEL_ONBOARDING_TOPICS_SUCCESS: return getLabelOnboardingTopics(state, action);
    case actionTypes.SHOW_PERSONALIZATION: return showPersonalization(state, action);
    case actionTypes.GET_PERSONALIZATION_REQ: return getPersonalizationQuestionsRequest(state);
    case actionTypes.GET_PERSONALIZATION_SUCCESS: return getPersonalizationQuestionsSuccess(state, action);
    case actionTypes.GET_PERSONALIZATION_FAILURE: return getPersonalizationQuestionsFailure(state);
    case actionTypes.SEND_PERSONALIZATION_REQ: return sendPersonalizationAnswersRequest(state);
    case actionTypes.SEND_PERSONALIZATION_SUCCESS: return sendPersonalizationAnswersSuccess(state);
    case actionTypes.SEND_PERSONALIZATION_FAILURE: return sendPersonalizationAnswersFailure(state);
    case actionTypes.GET_RATING_REQ: return getRatingQuestionsRequest(state);
    case actionTypes.GET_RATING_SUCCESS: return getRatingQuestionsSuccess(state, action);
    case actionTypes.GET_RATING_FAILURE: return getRatingQuestionsFailure(state);
    case actionTypes.SEND_RATING_REQ: return sendRatingAnswersRequest(state);
    case actionTypes.SEND_RATING_SUCCESS: return sendRatingAnswersSuccess(state);
    case actionTypes.SEND_RATING_FAILURE: return sendRatingAnswersFailure(state);
    case actionTypes.RESET_RATING_SURVEY: return resetRatingSurvey(state);
    case actionTypes.GET_REVIEW_REQ: return getReviewQuestionsRequest(state);
    case actionTypes.GET_REVIEW_SUCCESS: return getReviewQuestionsSuccess(state, action);
    case actionTypes.GET_REVIEW_FAILURE: return getReviewQuestionsFailure(state);
    case actionTypes.SEND_REVIEW_REQ: return sendReviewAnswersRequest(state);
    case actionTypes.SEND_REVIEW_SUCCESS: return sendReviewAnswersSuccess(state);
    case actionTypes.SEND_REVIEW_FAILURE: return sendReviewAnswersFailure(state);
    case actionTypes.RESET_REVIEW_SURVEY: return resetReviewSurvey(state);
    case actionTypes.LOGOUT: return logout(state);
    case actionTypes.GET_BRANDS_SUCCESS: return getBrandsSuccess(state, action);
    case actionTypes.GET_TIMEZONE_REQUEST: return getTimezonesRequest(state);
    case actionTypes.GET_TIMEZONE_SUCCESS: return getTimezonesSuccess(state, action);
    case actionTypes.GET_TIMEZONE_FAILURE: return getTimezonesFailure(state);
    case actionTypes.SHOW_NOTICE: return showNotice(state, action);
    case actionTypes.HIDE_NOTICE: return hideNotice(state, action);
    case actionTypes.GET_LANGUAGES_REQUEST: return getLanguagesRequest(state);
    case actionTypes.GET_LANGUAGES_SUCCESS: return getLanguagesSuccess(state, action);
    case actionTypes.GET_LANGUAGES_FAILURE: return getLanguagesFailure(state);
    case actionTypes.SET_SUB_TAB_HOME: return setSubTabHome(state, action);
    case actionTypes.SET_TAB_HOME: return setTabHome(state, action);
    case actionTypes.SET_SUBSCRIPTION_TIMEOUT_FUNC: return setSubscriptionTimeoutFunc(state, action);
    case actionTypes.CLEAR_SUBSCRIPTION_TIME_OUT: return clearTimeout(state);
    case actionTypes.TOGGLE_IFRAME_POPUP: return toggleIframePopup(state, action);
    case actionTypes.GET_SORT_BY_RELEVANCE_REQUEST: return getSortByRelevanceRequest(state);
    case actionTypes.GET_SORT_BY_RELEVANCE_SUCCESS: return getSortByRelevanceSuccess(state, action);
    case actionTypes.GET_SORT_BY_RELEVANCE_FAILURE: return getSortByRelevanceFailure(state);
    case actionTypes.SET_URL_REDIRECT_AFTER_LOGIN: return setUrlRedirectAfterLogin(state, action);
    case actionTypes.SET_COLOR_PICKER: return setColorPicker(state, action);
    case actionTypes.SET_GRADIENT_STYLE: return setGradientStyle(state, action);
    case actionTypes.SET_LEADERBOARD_PRESENCE: return setLeaderboardPresence(state, action);
    case actionTypes.SET_NOTIFICATION_CHANNELS: return setNotificationChannels(state, action);
    case actionTypes.SET_CATALOGUE_DOC_TITLE: return setCatalogueDocTitle(state, action);
    case actionTypes.SET_OPEN_MENU: return setOpenMenu(state, action);
    case actionTypes.GET_HOT_L1_REQUEST: return getHotL1Request(state);
    case actionTypes.GET_HOT_L1_SUCCESS: return getHotL1Success(state, action);
    case actionTypes.GET_HOT_L1_FAILURE: return getHotL1Failure(state);
    case actionTypes.GET_L1_MAP: return getL1Map(state, action);
    case actionTypes.SET_SHOW_LABEL_PREVIEW: return setShowLabelPreviewBanner(state, action);
    case actionTypes.SET_SHOW_CMS_PREVIEW: return setShowCMSPreviewBanner(state, action);
    case actionTypes.SET_COLLECTION_PREVIEW: return setShowCollectionPreviewBanner(state, action);
    case actionTypes.SET_PROMPT_METHOD: return setPromptMethod(state, action);
    case actionTypes.SET_SHOW_CELEBRATION_MODAL: return setShowCelebrationModal(state, action);
    case actionTypes.GET_ONBOARDING_CERTIFICATE_PDF_REQUEST: return startDownloadCelebrationPdf(state);
    case actionTypes.GET_ONBOARDING_CERTIFICATE_PDF_END: return endDownloadCelebrationPdf(state);
    case actionTypes.SET_SHOW_AREA_OF_EXPERTISE: return setShowAreaOfExpertiseModal(state, action);
    case actionTypes.SET_SHOW_VISIONE_CARE_MENU: return setShowVsionCareMenuModal(state,action);
    case actionTypes.SET_SHOW_COLLECTIONS_MENU_MODAL: return setShowCollectionsMenuModal(state, action);
    case actionTypes.SET_SHOW_ED_PATH_MENU_MODAL: return setShowEdPathMenuModal(state, action);
    case actionTypes.SET_SHOW_MODAL_BY_TYPE: return setShowModalByType(state, action);
    case actionTypes.SET_AUTO_MANAGE_HISTORY_IFRAME_ON_CLOSE: return setAutoManageHistoryIframeOnClose(state, action);
    case actionTypes.SET_SHOW_MODAL_CONTACT_CUSTOMER_SERVICE: return setShowModalContactCustomerService(state, action);
    case actionTypes.SET_SHOW_MODAL_REQUEST_JOIN: return setShowModalRequestJoin(state, action);
    case actionTypes.SET_TEACHER_USERNAME: return setTeacherUsername(state, action);
    case actionTypes.SET_USER_SIMULATED: return setUserSimulated(state, action);
    //case actionTypes.SET_ALREADY_IN_SIMULATION: return setAlreadyInSimulation(state, action);
    case actionTypes.SET_HIDE_BANNER_WAS_CLICKED: return setHideBannerWasClicked(state, action);
    case actionTypes.SET_EXIT_BANNER_WAS_CLICKED: return setExitBannerWasClicked(state, action);
    case actionTypes.SET_SHOW_LABEL_PERSONIFICATION_BANNER: { return setShowLaberPersonificationBanner(state, action) }
    case actionTypes.SET_SHOW_DIALOG_USERGROUPSIMULATION_PAGE: { return setShowDialogUserGroupSimulationPage(state, action) }
    case actionTypes.SET_LP_IN_SAVING_PROGRESS: { return setLPInSavingProgress(state, action) }
    case actionTypes.UNSET_LP_IN_SAVING_PROGRESS: { return unsetLPInSavingProgress(state, action) }
    case actionTypes.GET_LINKS_HEADER_FROM_CMS: { return setLinksHeaderFromCMS(state, action) }
    case actionTypes.GET_BUTTON_HEADER_FROM_CMS: { return setVisibleBtnHeaderFromCMS(state, action) }
    //LEON 4033
    case actionTypes.GET_CERTIFICATES_FILTER_COMPLETED: { return getCertificatesFilterCompleted(state, action) }
    case actionTypes.GET_CERTIFICATES_FILTER_FAILED: { return getCertificatesFilterFailed(state, action) }
    case actionTypes.GET_CERTIFICATES_FILTER_REQUEST: { return getCertificatesFilterRequest(state, action) }
    // MODALS V3
    case actionTypes.SET_MODALS_V3_OPEN: { return setModalsV3Open(state, action) }
    default:
      return state;
  }
};
export default reducer;
